
import { computed, ComputedRef, defineComponent } from "vue";
import FormComponent from "@/components/FormComponent.vue";
import { useI18n } from "vue-i18n";
import { useGtm } from "@gtm-support/vue-gtm";

interface ContactDetails {
  email: string;
  phones: string[];
  location: {
    title: string;
    link: string;
  };
}

const messages = {
  pl: {
    contact: {
      title: "Porozmawiajmy o samochodzie",
      subTitle: "Jeżeli masz jakiekolwiek pytania zapraszamy do kontaktu",
    },
    form: {
      title: "Napisz do nas",
    },
    contactDetails: {
      title: "Dane kontaktowe",
      writeOrCall: "Napisz lub zadzwoń",
      headquarters: "Nasza siedziba",
      checkSocials: "Zobacz nas na",
    },
    phones: ["+48 666 57 57 50", "+48 508 32 61 96"],
  },
  de: {
    contact: {
      title: "Besprechen wir über das Auto",
      subTitle: "Wenn Sie Fragen haven, kontaktieren Sie uns bitte",
    },
    form: {
      title: "Schreiben Sie zu uns",
    },
    contactDetails: {
      title: "Kontaktdaten",
      writeOrCall: "Schreiben oder anrufen",
      headquarters: "Unser Hauptsitz",
      checkSocials: "Sehen Sie uns auf",
    },
    phones: ["+49 151 41950212", "+48 666 57 57 50"],
  },
  en: {
    contact: {
      title: "Let's talk about your car",
      subTitle:
        "If you have any questions, feel free to call us or send an email",
    },
    form: {
      title: "Write to us",
    },
    contactDetails: {
      title: "Contact details",
      writeOrCall: "Message or call us",
      headquarters: "Our headquarters",
      checkSocials: "Follow us on",
    },
    phones: ["+49 151 41950212", "+48 666 57 57 50"],
  },
};

export default defineComponent({
  name: "Contact",
  components: { FormComponent },
  setup() {
    const { t } = useI18n({
      messages,
    });

    const gtm = useGtm();

    const onPhoneNumberClick = () => {
      gtm?.trackEvent({
        event: "interaction",
        category: "phone",
        action: "click",
        label: "phone number click",
        value: 5000,
        noninteraction: false,
      });
    };

    const contactDetails: ComputedRef<ContactDetails> = computed(() => ({
      email: "info@dpvcars.pl",
      phones: [t("phones[0]"), t("phones[1]")],
      location: {
        title: "Mieszczańska 11, 50-201 Wrocław",
        link: "https://goo.gl/maps/PBkFBgjPMo13Z7yL8",
      },
    }));

    return { t, contactDetails, onPhoneNumberClick };
  },
});
