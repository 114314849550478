<template>
  <form method="post" @submit.prevent="sendEmail" novalidate autocomplete="off">
    <div
      class="input"
      :class="{
        invalid: v$.name.$error,
        succeed: !v$.name.$error && !v$.name.required.$invalid,
      }"
    >
      <input
        type="text"
        id="name"
        name="name"
        :placeholder="t('inputs.name.placeholder')"
        class="input__module"
        v-model="state.fname"
        autocomplete="off"
      />
      <input
        type="text"
        id="v$i"
        name="v$i"
        @blur="v$.name.$touch()"
        v-model="state.name"
        :placeholder="t('inputs.name.placeholder')"
        autocomplete="off"
        :disabled="emailLoading"
      />
      <p v-if="v$.name.required.$invalid">
        {{ t("inputs.name.errors.required") }}
      </p>
    </div>
    <div
      class="input"
      :class="{
        invalid: v$.email.$error,
        succeed: !v$.email.$error && !v$.email.required.$invalid,
      }"
    >
      <input
        type="text"
        name="email"
        id="email"
        :placeholder="t('inputs.email.placeholder')"
        class="input__module"
        v-model="state.femail"
        autocomplete="off"
      />
      <input
        type="text"
        name="v$m"
        id="v$m"
        @blur="v$.email.$touch()"
        v-model="state.email"
        :placeholder="t('inputs.email.placeholder')"
        autocomplete="off"
        :disabled="emailLoading"
      />
      <p v-if="v$.email.required.$invalid">
        {{ t("inputs.email.errors.required") }}
      </p>
      <p v-if="v$.email.email.$invalid">
        {{ t("inputs.email.errors.invalid") }}
      </p>
    </div>
    <div
      class="textarea"
      :class="{
        invalid: v$.message.$error,
        succeed: !v$.message.$error && !v$.message.required.$invalid,
      }"
    >
      <textarea
        rows="2"
        name="comments"
        :placeholder="t('inputs.textarea.placeholder')"
        class="input__module"
        v-model="state.fmessage"
        autocomplete="off"
      />
      <textarea
        rows="2"
        name="v$t"
        @blur="v$.message.$touch()"
        v-model="state.message"
        :placeholder="t('inputs.textarea.placeholder')"
        autocomplete="off"
        :disabled="emailLoading"
      />
      <p v-if="v$.message.required.$invalid">
        {{ t("inputs.textarea.errors.required") }}
      </p>
    </div>
    <div class="input__module">
      <input type="checkbox" name="check" id="check" v-model="state.check" />
      <label for="check">Check</label>
    </div>
    <input
      type="submit"
      :value="
        isEmailSent
          ? t('inputs.submitButton.status.sent')
          : emailLoading
          ? t('inputs.submitButton.status.sending')
          : t('inputs.submitButton.status.send')
      "
      :disabled="v$.$invalid"
      :class="{ emailSent: isEmailSent }"
    />
    <p class="emailResponse" v-if="isEmailSent">
      {{ t("inputs.response") }} <span>#dpvteam</span>
    </p>
  </form>
</template>
<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";
import { useGtm } from "@gtm-support/vue-gtm";

interface FormState {
  name: string;
  email: string;
  message: string;
  fname: string;
  femail: string;
  fmessage: string;
  check: boolean;
}

const messages = {
  pl: {
    inputs: {
      name: {
        placeholder: "Imię i nazwisko",
        errors: {
          required: "Proszę uzupełnić opowiązkowe pole.",
        },
      },
      email: {
        placeholder: "Adres email",
        errors: {
          required: "Proszę uzupełnić opowiązkowe pole.",
          invalid: "Proszę podać prawidłowy adres email.",
        },
      },
      textarea: {
        placeholder: "Jak możemy pomóc?",
        errors: {
          required: "Proszę uzupełnić opowiązkowe pole.",
        },
      },
      submitButton: {
        status: {
          sent: "Wysłano",
          sending: "Przesyłanie...",
          send: "Wyślij wiadomość",
        },
      },
      response: "Odpiszemy niebawem",
    },
  },
  de: {
    inputs: {
      name: {
        placeholder: "Name und Nachname",
        errors: {
          required: "Bitte füllen Sie das Pflichtfeld aus.",
        },
      },
      email: {
        placeholder: "E-Mail-Adresse",
        errors: {
          required: "Bitte füllen Sie das Pflichtfeld aus.",
          invalid: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
        },
      },
      textarea: {
        placeholder: "Wie können wir helfen?",
        errors: {
          required: "Bitte füllen Sie das Pflichtfeld aus.",
        },
      },
      submitButton: {
        status: {
          sent: "Gesendet",
          sending: "Nachrichten...",
          send: "Schicken",
        },
      },
      response: "Wir werden bald antworten",
    },
  },
  en: {
    inputs: {
      name: {
        placeholder: "First and last name",
        errors: {
          required: "Please complete the mandatory field.",
        },
      },
      email: {
        placeholder: "E-mail address",
        errors: {
          required: "Please complete the mandatory field.",
          invalid: "Please enter a valid email address.",
        },
      },
      textarea: {
        placeholder: "How can we help?",
        errors: {
          required: "Please complete the mandatory field.",
        },
      },
      submitButton: {
        status: {
          sent: "Sent",
          sending: "Sending...",
          send: "Send a message",
        },
      },
      response: "We will reply soon",
    },
  },
};

export default defineComponent({
  name: "FormComponent",
  setup() {
    const { t } = useI18n({
      messages,
    });

    const state: FormState = reactive({
      name: "",
      email: "",
      message: "",
      fname: "", // prevents b users from spamming
      femail: "",
      fmessage: "",
      check: false,
    });

    const rules = {
      name: { required },
      email: { required, email },
      message: { required },
    };

    const v$ = useVuelidate(rules, state);

    let externalScript: HTMLScriptElement;
    const loadSMTP = () => {
      externalScript = document.createElement("script");
      externalScript.setAttribute("src", "https://smtpjs.com/v3/smtp.js");
      document.head.appendChild(externalScript);
    };

    loadSMTP();

    const gtm = useGtm();

    const emailLoading = ref(false);
    const isEmailSent = ref(false);
    const sendEmail = async () => {
      emailLoading.value = true;
      const isFormCorrect = await v$.value.$validate();
      if (
        !isFormCorrect ||
        state.fname ||
        state.femail ||
        state.fmessage ||
        state.check
      ) {
        emailLoading.value = false;
        return;
      }

      const host = process.env.VUE_APP_smtph;
      const uname = process.env.VUE_APP_smtpu;
      const pw = process.env.VUE_APP_smtpp;

      /* eslint-disable no-undef */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Email.send({
        // SecureToken: process.env.VUE_APP_smtp,
        Host: host,
        Username: uname,
        Password: pw,
        To: "info@dpvcars.pl",
        From: state.email,
        Subject: "Wiadomość ze strony internetowej.",
        Body: state.message,
      }).then((message: string) => {
        console.log(message);
        state.name = "";
        state.email = "";
        state.message = "";
        state.check = false;

        v$.value.$reset();
        isEmailSent.value = true;
        emailLoading.value = false;
        setTimeout(() => {
          isEmailSent.value = false;
        }, 5000);
      });

      gtm?.trackEvent({
        event: "interaction",
        category: "email",
        action: "click",
        label: "Email send button click",
        value: 5000,
        noninteraction: false,
      });
    };

    return { t, state, v$, sendEmail, emailLoading, isEmailSent };
  },
});
</script>

<style lang="scss">
form {
  @apply flex justify-center items-center flex-col;
  .input__module {
    @apply hidden;
  }
  .input,
  .textarea {
    @apply w-full mt-4 shadow-none;
    &:first-of-type {
      @apply mt-0;
    }
    input[type="text"],
    textarea {
      background: transparent;
      padding-bottom: 10px;
      @apply w-full border-b-2 border-white placeholder-white shadow-none;
      &:focus {
        outline: none;
        @apply outline-none;
      }
    }

    p {
      @apply hidden text-white text-sm;
    }

    &.invalid {
      input[type="text"],
      textarea {
        @apply border-error;
      }

      p {
        @apply block text-error;
      }
    }

    textarea {
      min-height: 12rem;
      padding: 10px;
      @apply border-2 h-48 max-h-52;
    }
  }
  input[type="submit"] {
    @apply w-full text-sm mt-4 text-center;
    &:disabled {
      @apply cursor-not-allowed;
      &:hover {
        @apply bg-secondaryGray text-gray;
      }
    }
    &.emailSent {
      @apply bg-white text-black;
      &:hover {
        @apply bg-white text-black cursor-default;
      }
    }
  }
  .emailResponse {
    @apply text-brown mt-2;
    span {
      @apply font-bold uppercase;
    }
  }
}
</style>
