
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "InfoSection",
  props: {
    sectionsDetails: Array,
  },
  setup() {
    const { t } = useI18n({
      messages: {
        pl: {
          button: "Zobacz",
        },
        de: {
          button: "Suchen",
        },
        en: {
          button: "Search",
        },
      },
    });

    return { t };
  },
});
