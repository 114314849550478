
import { chosenLocale } from "@/main";
import { computed, defineComponent, PropType, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

interface Locale {
  flag: string;
  shortName: string;
  name: string;
}

export default defineComponent({
  name: "LanguagueSwitch",
  props: {
    locales: {
      type: Array as PropType<Locale[]>,
      required: true,
    },
    theme: {
      type: String as PropType<"dark" | "light">,
      required: true,
    },
  },
  setup(props) {
    const { locale } = useI18n({ useScope: "global" });
    const store = useStore();

    const currentLocale = computed(() => store.getters.currentLocale);

    const setCurrentLocale = (locale: string) => {
      store.commit("setCurrentLocale", locale);
    };

    const handleLocaleChange = (event: { target: HTMLInputElement }) => {
      setCurrentLocale(event.target.value);
    };

    setCurrentLocale(
      props.locales
        .map((locale) => locale.shortName)
        .find((locale) => locale === chosenLocale) || props.locales[0].shortName
    );

    watch(currentLocale, (newLocale) => {
      locale.value = newLocale;
      setCurrentLocale(newLocale);
    });

    return { currentLocale, handleLocaleChange };
  },
});
