
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
} from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

interface NavItem {
  title: string;
  section: string;
}

const messages = {
  pl: {
    navItems: [
      {
        title: "O Nas",
      },
      {
        title: "Import",
      },
      {
        title: "Oferta",
      },
      {
        title: "Aktualności",
      },
      {
        title: "Kalkulator",
      },
      {
        title: "Warsztat",
      },
      {
        title: "Finansowanie",
      },
      {
        title: "Kontakt",
      },
    ],
  },
  de: {
    navItems: [
      {
        title: "Über uns",
      },
      {
        title: "Import",
      },
      {
        title: "Offerte",
      },
      {
        title: "Nachrichten",
      },
      {
        title: "Rechner",
      },
      {
        title: "Werkstatt",
      },
      {
        title: "Finanzierung",
      },
      {
        title: "Kontakt",
      },
    ],
  },
  en: {
    navItems: [
      {
        title: "About us",
      },
      {
        title: "Import",
      },
      {
        title: "Offer",
      },
      {
        title: "News",
      },
      {
        title: "Calculator",
      },
      {
        title: "Garage",
      },
      {
        title: "Financing",
      },
      {
        title: "Contact",
      },
    ],
  },
};

export default defineComponent({
  name: "NavOverlay",
  setup() {
    const { t } = useI18n({
      messages,
    });

    const store = useStore();
    const navItems: ComputedRef<NavItem[]> = computed(() => [
      {
        title: t("navItems[0].title"),
        section: ".aboutUs",
      },
      {
        title: t("navItems[1].title"),
        section: ".importSection",
      },
      {
        title: t("navItems[2].title"),
        section: ".aboutCars",
      },
      {
        title: t("navItems[3].title"),
        section: ".news",
      },
      {
        title: t("navItems[4].title"),
        section: ".pricingCalculator",
      },
      {
        title: t("navItems[5].title"),
        section: ".info__section > .info__content:first-of-type",
      },
      {
        title: t("navItems[6].title"),
        section: ".info__section > .info__content:last-of-type",
      },
      {
        title: t("navItems[7].title"),
        section: ".contact",
      },
    ]);

    const setNav = (nav: boolean) => store.commit("setNav", nav);

    const onScrollDone = () => {
      setNav(false);
    };

    const overlay: Ref<HTMLElement | null> = ref(null);

    const handleOverlayClick = (e: MouseEvent) => {
      if (
        overlay.value &&
        (overlay.value as HTMLElement).isSameNode(e.target as Node)
      ) {
        setNav(false);
      }
    };

    onMounted(() => {
      ((overlay.value as unknown) as HTMLElement).addEventListener(
        "click",
        handleOverlayClick
      );
    });

    onBeforeUnmount(() => {
      ((overlay.value as unknown) as HTMLElement).removeEventListener(
        "click",
        handleOverlayClick
      );
    });

    return {
      navItems,
      setNav,
      onScrollDone,
      overlay,
    };
  },
});
