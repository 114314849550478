
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import LanguagueSwitch from "@/components/LanguagueSwitch.vue";
import { AVAILABLE_LOCALES } from "@/main";

export default defineComponent({
  name: "TheNavbar",
  components: { LanguagueSwitch },
  setup() {
    const store = useStore();
    const isNavOpen = computed(() => store.getters.isNavOpen);
    const shouldLoadMaps = (value: boolean) =>
      store.commit("setLoadMaps", value);

    const initialScrollOffset = computed(() => store.getters.navTriggerValue);
    const lastScrollPosition = ref(0);
    const offset = 5;
    const showNavbar = ref(false);
    const desktopMedia = window.matchMedia(
      "(min-width:1024px) and (min-height: 960px)"
    );
    const loadMapsOffset = desktopMedia.matches ? 8000 : 12000;

    const onScroll = () => {
      // lazy load maps component
      if (window.pageYOffset > loadMapsOffset) {
        shouldLoadMaps(true);
      }

      if (window.pageYOffset > initialScrollOffset.value) {
        if (window.pageYOffset < 0) return;
        if (Math.abs(window.pageYOffset - lastScrollPosition.value) < offset)
          return;

        showNavbar.value = window.pageYOffset < lastScrollPosition.value;
        lastScrollPosition.value = window.pageYOffset;
      } else {
        showNavbar.value = false;
      }
    };

    onMounted(() => {
      lastScrollPosition.value = window.pageYOffset;
      window.addEventListener("scroll", onScroll, { passive: true });
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", onScroll);
    });

    return {
      isNavOpen,
      setNav: (nav: boolean) => store.commit("setNav", nav),
      showNavbar,
      AVAILABLE_LOCALES,
    };
  },
  // mounted() {
  //   if (this.$route.meta.initialNav) this.showNavbar = true;
  //   this.lastScrollPosition = window.pageYOffset;
  //   window.addEventListener("scroll", this.onScroll);
  // },
  // beforeUnmount() {
  //   window.removeEventListener("scroll", this.onScroll);
  // },
  // methods: {
  //   onScroll() {
  //     console.log(window.pageYOffset, this.initialScrollOffset);

  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     if (this.lastScrollPosition > this.initialScrollOffset) {
  //       if (window.pageYOffset < 0) return;
  //       if (
  //         Math.abs(window.pageYOffset - this.lastScrollPosition) < this.offset
  //       )
  //         return;

  //       this.showNavbar = window.pageYOffset < this.lastScrollPosition;
  //       this.lastScrollPosition = window.pageYOffset;
  //     }
  //   },
  // },
});
