
import { computed, ComputedRef, defineComponent, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import axios from "axios";
import { Locale } from "@/store/modules/utils";

interface ExchangeRate {
  currency: string;
  code: string;
  bid?: number;
  ask?: number;
  mid?: number;
}

interface NBPResponse {
  effectiveDate: string;
  rates: ExchangeRate[];
  actionDate: string;
}

interface SalePrice {
  min: number;
  fee: number;
  max: number;
  percentage?: number;
}

const messages = {
  pl: {
    header: {
      calculatorTitle: "Kalkulator",
      subTitle: "Sprawdź orientacyjny koszt importu pojazdu",
    },
    description: {
      title:
        "Kalkulator posiada ceny orientacyjne, po dokładną wycenę zgłoś się do nas bezpośrednio za pomocą formularza kontaktowego lub telefonicznie.",
      mainParagraphs: [
        "Przy imporcie niektórych pojazdów takich jak łodzie, autobusy czy maszyny budowlane - koszt importu ulega zmianie. Import spoza UE może wymagać dodatkowych zezwoleń i certyfikatów.",
        "Import pojazdów i aut z USA do Polski wiąże się z pewnymi kosztami. Kształtują się one w następujący sposób:",
      ],
      categories: [
        {
          title: "Akcyza:",
          details: [
            "3,1% dla silników o pojemności do 2.0L",
            "18,6% dla silników o pojemności powyżej 2.0L",
            "Brak akcyzy na łodzie motorowe",
          ],
        },
        {
          title: "Podatek w VAT:",
          details: ["19% w Niemczech", "23% w Polsce", "21% w Holandii"],
        },
        {
          title: "Cło:",
          details: ["Samochód osobowy 10%", "Motocykl 6%", "Quad/atv 10%"],
        },
      ],
      addnotation:
        "Przed licytacją należy wpłacić 10% wartości wylicytowanej kwoty. W przypadku przegranej licytacji kaucja jest zwracana do 5 dni roboczych.",
    },
    currencies: {
      title: "Kursy walut",
      currencies: "Waluty",
      buy: "Kupno",
      sell: "Sprzedaż",
      update: "Aktualizacja",
      provider: "Narodowy Bank Polski",
    },
    calculator: {
      payment: {
        title: "Kalkulator opłat",
        providePrice: "Podaj cenę pojazdu (USD)",
        pricePlaceholder: "Cena",
        auctionCostsTitle: "Koszty aukcyjne około",
        provideState:
          "Wybierz region (stan), w którym znajduje się Twój pojazd",
        // state names translations here,
        shipTransportCost: "Koszt transportu z USA do portu w Bremerhaven",
        withoutCustomsClearance: "Cena bez odprawy celnej",
      },
      customs: {
        title: "Odprawa celna",
        vehicleValue: "Wartość pojazdu (USD)",
        duty: "Cło",
        dutyValues: ["Samochód 10%", "Motocykl 6%", "Quad / ATV 10%"],
        countries: ["Niemcy", "Polska", "Holandia"],
        vatTax: "VAT",
        customsAgency: "Agencja celna i rozładunek",
        dutyCost: "Koszt odprawy celnej",
      },
      transport: {
        title: "Transport z portu i dodatkowe usługi",
        details: [
          "Transport pod dom",
          "Tłumaczenie dokumentów",
          "Opinia rzeczoznawcy",
        ],
        fee: "Prowizja",
      },
      totalCost: "Łączny koszt",
    },
  },
  de: {
    header: {
      calculatorTitle: "Kalkulation",
      subTitle:
        "Überprüfen Sie die richtigen Kosten für die Einhuhr des Fahrzeugs",
    },
    description: {
      title:
        "Der Rechner hat ungefähre Preise, für ein genaues Angebot kontaktieren Sie uns bitte direkt über das Kontaktformular oder telefonisch.",
      mainParagraphs: [
        "Beim Import bestimmter Fahrzeuge wie Boote, Busse oder Baumaschinen, ändern sich die Importkosten. Für die Einfuhr von außerhalb der EU sind möglicherweise zusätzliche Genehmigungen und Bescheinigungen erforderlich.",
        "Import von Fahrzeugen aus den USA nach Deutschland ist mit gewissen Kosten verbunden. Wie folgt:",
      ],
      categories: [
        {
          title: "Formalitäten für Kfz- Zulassung in Deutschland:",
          details: [
            "Fahrzeugreparatur",
            "Fahrzeugumbau, Anpassung an den Straßenverkehr in Europa",
            "TÜV Untersuchung und Kfz- Zulassung",
          ],
        },
        {
          title: "Mehrwertsteuer:",
          details: [
            "19% in Deutschland",
            "23% in Polen",
            "21% in den Niederlanden",
          ],
        },
        {
          title: "Zoll:",
          details: ["PKW 10%", "Motorrad 6%", "Quad / ATV 10%"],
        },
      ],
      addnotation:
        "Vor der Versteigerung sind 10 % des Wertes der Versteigerungssumme zu zahlen. Im Falle einer verlorenen Auktion wird die Anzahlung bis zu 5 Werktage zurückerstattet.",
    },
    currencies: {
      title: "Wechselkurse",
      currencies: "Währung",
      buy: "Kauf",
      sell: "Verkauf",
      update: "Aktualisieren",
      provider: "Deutsche Bundesbank",
    },
    calculator: {
      payment: {
        title: "Gebührenrechner",
        providePrice: "Fahrzeugpreis (USD)",
        pricePlaceholder: "Preis",
        auctionCostsTitle: "Auktionskosten ca.",
        provideState:
          "Wählen Sie den Staat aus, in dem sich Ihr Fahrzeug in Amerika befindet",
        // state names translations here,
        shipTransportCost:
          "Die Transportkosten von den USA zum Hafen Bremerhaven",
        withoutCustomsClearance: "Preis ohne Zollabfertigung",
      },
      customs: {
        title: "Zollabfertigung",
        vehicleValue: "Fahrzeugwert (USD)",
        duty: "Zoll",
        dutyValues: ["der PKW 10%", "das Motorrad 6%", "Quad/atv 10%"],
        countries: ["Deutschland", "Polen", "die Niederlande"],
        vatTax: "MwSt.",
        customsAgency: "Zollagentur und Entladung",
        dutyCost: "Zollabfertigungskosten",
      },
      transport: {
        title: "Transport und zusätzliche Dienstleistungen",
        details: [
          "Transport direkt zum Haus",
          "Übersetzung von Dokumenten",
          "Expertenmeinung",
        ],
        fee: "Provision",
      },
      totalCost: "Gesamtkosten",
    },
  },
  en: {
    header: {
      calculatorTitle: "Cost Calculator",
      subTitle: "Get an estimate for your next car with taxes and shipping",
    },
    description: {
      title:
        "The cost calculator can only give you an estimate. For a more accurate calculation please contact us via contact form or call us.",
      mainParagraphs: [
        "Import costs are different for vehicles like boats, buses or construction machinery. It may require additional permits and certificates.",
        "Here are the costs of importing a car, broken down into details:",
      ],
      categories: [
        {
          title: "Formalities connected with vehicle registration:",
          details: [
            "Vehicle repair",
            "Vehicle conversion, adaptation to road traffic in Europe",
            "TÜV test and vehicle registration",
          ],
        },
        {
          title: "VAT Tax:",
          details: [
            "19% in Germany",
            "23% in Poland",
            "21% in the Netherlands",
          ],
        },
        {
          title: "Customs fee:",
          details: ["Car 10%", "Motorcycle 6%", "Quad / ATV 10%"],
        },
      ],
      addnotation:
        "A 10% advance should be paid before the auction. Should we fail to win the auction, money will be transferred to your account in 5 working days.",
    },
    currencies: {
      title: "Exchange rates",
      currencies: "Currencies",
      buy: "Buy",
      sell: "Sell",
      update: "Update",
      provider: "National Bank of Poland",
    },
    calculator: {
      payment: {
        title: "Fee calculator",
        providePrice: "Car price (USD)",
        pricePlaceholder: "Price",
        auctionCostsTitle: "Auction costs (estimate)",
        provideState: "Choose a region (state), in which the car is located",
        // state names translations here,
        shipTransportCost:
          "Cost of transportation from the USA to shipping port in Bremerhaven",
        withoutCustomsClearance: "Price without Customs fees",
      },
      customs: {
        title: "Customs",
        vehicleValue: "Car value (USD)",
        duty: "Duty",
        dutyValues: ["Car 10%", "Motorcycle 6%", "Quad/atv 10%"],
        countries: ["Germany", "Poland", "The Netherlands"],
        vatTax: "VAT",
        customsAgency: "Customs Agency and unloading",
        dutyCost: "Customs clearance cost",
      },
      transport: {
        title: "Transportation and additional services",
        details: [
          "Door to door shipment",
          "Translation of documents",
          "Experts opinion",
        ],
        fee: "Commission",
      },
      totalCost: "Full cost",
    },
  },
};

export default defineComponent({
  name: "PricingCalculator",
  async setup() {
    const { t, locale } = useI18n({
      useScope: "global",
      messages,
    });

    const availableCurrencies: Record<Locale, string> = {
      pl: "PLN",
      de: "€",
      en: "€",
    };

    const currency = computed(
      () => availableCurrencies[locale.value as Locale]
    );

    const calculatorDescription = computed(() => ({
      title: t("description.title"),
      mainParagraphs: [
        t("description.mainParagraphs[0]"),
        t("description.mainParagraphs[1]"),
      ],
      categories: [
        {
          title: t("description.categories[0].title"),
          details: [
            t("description.categories[0].details[0]"),
            t("description.categories[0].details[1]"),
            t("description.categories[0].details[2]"),
          ],
        },
        {
          title: t("description.categories[1].title"),
          details: [
            t("description.categories[1].details[0]"),
            t("description.categories[1].details[1]"),
            t("description.categories[1].details[2]"),
          ],
        },
        {
          title: t("description.categories[2].title"),
          details: [
            t("description.categories[2].details[0]"),
            t("description.categories[2].details[1]"),
            t("description.categories[2].details[2]"),
          ],
        },
      ],
      addnotation: t("description.addnotation"),
    }));

    const transportDetails = computed(() =>
      [
        {
          title: t("calculator.transport.details[0]"),
          price: 2000,
        },
        {
          title: t("calculator.transport.details[1]"),
          price: 300,
        },
        {
          title: t("calculator.transport.details[2]"),
          price: 550,
        },
      ].filter((_, i) => locale.value === "pl" || i === 0)
    );

    // min - inclusive
    // max - exclusive
    const calculatorContent = computed(() => ({
      base: {
        auctionCosts: 550,
        auctCosts: {
          serviceFee: 59,
          highVolumeFee: [
            {
              min: 0,
              max: 100,
              fee: 1,
            },
            {
              min: 100,
              max: 200,
              fee: 40,
            },
            {
              min: 200,
              max: 300,
              fee: 60,
            },
            {
              min: 300,
              max: 350,
              fee: 75,
            },
            {
              min: 350,
              max: 400,
              fee: 90,
            },
            {
              min: 400,
              max: 500,
              fee: 100,
            },
            {
              min: 500,
              max: 600,
              fee: 130,
            },
            {
              min: 600,
              max: 700,
              fee: 145,
            },
            {
              min: 700,
              max: 800,
              fee: 160,
            },
            {
              min: 800,
              max: 900,
              fee: 175,
            },
            {
              min: 900,
              max: 1000,
              fee: 190,
            },
            {
              min: 1000,
              max: 1100,
              fee: 205,
            },
            {
              min: 1100,
              max: 1200,
              fee: 220,
            },
            {
              min: 1200,
              max: 1300,
              fee: 230,
            },
            {
              min: 1300,
              max: 1400,
              fee: 240,
            },
            {
              min: 1400,
              max: 1500,
              fee: 255,
            },
            {
              min: 1500,
              max: 1600,
              fee: 270,
            },
            {
              min: 1600,
              max: 1700,
              fee: 290,
            },
            {
              min: 1700,
              max: 1800,
              fee: 300,
            },
            {
              min: 1800,
              max: 2000,
              fee: 310,
            },
            {
              min: 2000,
              max: 2200,
              fee: 325,
            },
            {
              min: 2200,
              max: 2400,
              fee: 330,
            },
            {
              min: 2400,
              max: 2500,
              fee: 345,
            },
            {
              min: 2500,
              max: 3000,
              fee: 360,
            },
            {
              min: 3000,
              max: 3500,
              fee: 400,
            },
            {
              min: 3500,
              max: 4000,
              fee: 450,
            },
            {
              min: 4000,
              max: 4500,
              fee: 475,
            },
            {
              min: 4500,
              max: 5000,
              fee: 500,
            },
            {
              min: 5000,
              max: 6000,
              fee: 525,
            },
            {
              min: 6000,
              max: 7500,
              fee: 550,
            },
            {
              min: 7500,
              max: 20000,
              fee: 500,
              percentage: 0.01,
            },
            {
              min: 20000,
              max: Number.MAX_SAFE_INTEGER,
              fee: 0,
              percentage: 0.04,
            },
          ],
          internetBidFee: [
            {
              min: 0,
              max: 100,
              fee: 0,
            },
            {
              min: 100,
              max: 500,
              fee: 39,
            },
            {
              min: 500,
              max: 1000,
              fee: 49,
            },
            {
              min: 1000,
              max: 1500,
              fee: 69,
            },
            {
              min: 1500,
              max: 2000,
              fee: 79,
            },
            {
              min: 2000,
              max: 4000,
              fee: 89,
            },
            {
              min: 4000,
              max: 6000,
              fee: 99,
            },
            {
              min: 6000,
              max: 8000,
              fee: 119,
            },
            {
              min: 8000,
              max: Number.MAX_SAFE_INTEGER,
              fee: 129,
            },
          ] as SalePrice[],
        },
        statesCosts: [
          {
            title: "Alabama AL",
            transportCost: 1650,
          },
          {
            title: "Arizona AZ",
            transportCost: 2000,
          },
          {
            title: "Arkansas AR",
            transportCost: 1700,
          },
          {
            title: "Connectticut CT",
            transportCost: 1250,
          },
          {
            title: "Dakota Południowa SD",
            transportCost: 2100,
          },
          {
            title: "Dakota Północna ND",
            transportCost: 1750,
          },
          {
            title: "Delaware DE",
            transportCost: 1300,
          },
          {
            title: "Floryda FL",
            transportCost: 1650,
          },
          {
            title: "Georgia GA",
            transportCost: 1650,
          },
          {
            title: "Hawaje HI",
            transportCost: 2900,
          },
          {
            title: "Idaho ID",
            transportCost: 2100,
          },
          {
            title: "Illinois IL",
            transportCost: 1550,
          },
          {
            title: "Indiana IN",
            transportCost: 1550,
          },
          {
            title: "Iowa IA",
            transportCost: 1650,
          },
          {
            title: "Kalifornia CA",
            transportCost: 2250,
          },
          {
            title: "Kansas KS",
            transportCost: 1700,
          },
          {
            title: "Karolina Południowa SC",
            transportCost: 1550,
          },
          {
            title: "Karolina Północna NC",
            transportCost: 1500,
          },
          {
            title: "Kentucky KY",
            transportCost: 1500,
          },
          {
            title: "Kolorado CO",
            transportCost: 1950,
          },
          {
            title: "Luizjana LA",
            transportCost: 1800,
          },
          {
            title: "Maine ME",
            transportCost: 1400,
          },
          {
            title: "Maryland MD",
            transportCost: 1250,
          },
          {
            title: "Massachusetts MA",
            transportCost: 1250,
          },
          {
            title: "Michigan MI",
            transportCost: 1550,
          },
          {
            title: "Minnesota MN",
            transportCost: 1650,
          },
          {
            title: "Missisipi MS",
            transportCost: 1650,
          },
          {
            title: "Missouri MO",
            transportCost: 1600,
          },
          {
            title: "Montana MT",
            transportCost: 2100,
          },
          {
            title: "Nebraska NE",
            transportCost: 1750,
          },
          {
            title: "Nevada NV",
            transportCost: 2050,
          },
          {
            title: "New Hampshire NH",
            transportCost: 1400,
          },
          {
            title: "New Jersey NJ",
            transportCost: 1200,
          },
          {
            title: "Nowy Jork NY",
            transportCost: 1150,
          },
          {
            title: "Nowy Meksyk NM",
            transportCost: 1950,
          },
          {
            title: "Ohio OH",
            transportCost: 1500,
          },
          {
            title: "Oklahoma OK",
            transportCost: 1700,
          },

          {
            title: "Oregon OR",
            transportCost: 2200,
          },
          {
            title: "Pensylwania PA",
            transportCost: 1400,
          },
          {
            title: "Rhode Island RI",
            transportCost: 1350,
          },
          {
            title: "Teksas TX",
            transportCost: 1650,
          },
          {
            title: "Tennessee TN",
            transportCost: 1650,
          },
          {
            title: "Utah UT",
            transportCost: 1900,
          },
          {
            title: "Vermonta VT",
            transportCost: 1450,
          },
          {
            title: "Waszyngton WA",
            transportCost: 2200,
          },
          {
            title: "Wirginia VA",
            transportCost: 1450,
          },
          {
            title: "Wirginia Zachodnia WV",
            transportCost: 1450,
          },
          {
            title: "Wisconsin WI",
            transportCost: 1650,
          },
          {
            title: "Wyoming WY",
            transportCost: 1950,
          },
        ],
      },
      customs: {
        customDuty: [
          {
            title: t("calculator.customs.dutyValues[0]"),
            percentage: 0.1,
          },
          {
            title: t("calculator.customs.dutyValues[1]"),
            percentage: 0.06,
          },
          {
            title: t("calculator.customs.dutyValues[2]"),
            percentage: 0.1,
          },
        ],
        vatTax: [
          {
            title: t("calculator.customs.countries[0]"),
            percentage: 0.19,
          },
          {
            title: t("calculator.customs.countries[1]"),
            percentage: 0.23,
          },
          {
            title: t("calculator.customs.countries[2]"),
            percentage: 0.21,
          },
        ],
        customsAgency: 550,
      },
      transport: transportDetails.value,
      provision: 3500,
    }));

    const transportCheckboxes = ref([
      { isActive: true },
      { isActive: true },
      { isActive: true },
    ]);

    let displayCalculator = ref(true);

    const fetchExchangeRates = async (rateTable: string) => {
      return await axios.get(
        `https://api.nbp.pl/api/exchangerates/tables/${rateTable}`
      );
    };

    const matchPriceTier = (price: number, pricingTiers: SalePrice[]) => {
      return pricingTiers.find(
        (option: SalePrice) => option.min <= price && option.max > price
      );
    };

    const exchangeResponse = await fetchExchangeRates("a");
    const currencies = ["USD", "EUR"];
    const midRating = exchangeResponse.data[0].rates.filter(
      (rate: ExchangeRate) => currencies.includes(rate.code)
    );

    const usdMid = midRating.find((cur: ExchangeRate) => cur.code == "USD").mid;
    const eurMid = midRating.find((cur: ExchangeRate) => cur.code == "EUR").mid;

    const currenciesRates = computed(() => ({
      pln: 1,
      eur: eurMid,
      usd: usdMid,
    }));

    // In case of wrong or no data, disable calculator
    if (usdMid == 0 || eurMid == 0) displayCalculator.value = false;

    const activeState = ref(calculatorContent.value.base.statesCosts[0]);

    const activeCustomDuty = ref(
      calculatorContent.value.customs.customDuty[0].percentage
    );

    const activeVatTax = ref(
      calculatorContent.value.customs.vatTax[0].percentage
    );

    const carPrice = ref(1000);

    const internetFee = computed(
      () =>
        (
          matchPriceTier(
            carPrice.value,
            calculatorContent.value.base.auctCosts.internetBidFee
          ) as SalePrice
        ).fee
    );

    const saleFee = computed(
      () =>
        matchPriceTier(
          carPrice.value,
          calculatorContent.value.base.auctCosts.highVolumeFee
        ) as SalePrice
    );

    const percentageFee = computed(() =>
      saleFee.value.percentage ? saleFee.value.percentage * carPrice.value : 0
    );

    const auctionCosts = computed(
      () => saleFee.value.fee + percentageFee.value + internetFee.value
    );

    const basePrice = computed(
      () =>
        +carPrice.value + auctionCosts.value + +activeState.value.transportCost
    );

    const customDuty = computed(
      () => activeCustomDuty.value * basePrice.value * (usdMid / eurMid)
    );

    const vatValue = computed(
      () =>
        activeVatTax.value *
        (basePrice.value + activeCustomDuty.value * basePrice.value) *
        (usdMid / eurMid)
    );

    const customSubTotal = computed(
      () =>
        customDuty.value +
        vatValue.value +
        calculatorContent.value.customs.customsAgency
    );

    const provision = calculatorContent.value.provision;
    const activeTransportOptions = computed(() =>
      calculatorContent.value.transport
        .filter((_, index) => transportCheckboxes.value[index].isActive)
        .map((option) => option.price)
    );

    const activeTransportCost = computed(() =>
      activeTransportOptions.value.reduce(
        (sum, currentValue) => sum + currentValue,
        0
      )
    );

    const convertCurrency = (
      value: number,
      currentCurrencyRate: number,
      preferedCurrencyRate: number
    ) => {
      return (value * currentCurrencyRate) / preferedCurrencyRate;
    };

    // basePrice - usd
    // customSumtotal - euro
    // the rest - pln

    const totalCost: ComputedRef<number> = computed(() => {
      return convertCurrency(
        basePrice.value * usdMid +
          customSubTotal.value * eurMid +
          activeTransportCost.value +
          provision,
        currenciesRates.value["pln"],
        currenciesRates.value[locale.value === "pl" ? "pln" : "eur"]
      );
    });

    const ratingResponse = await fetchExchangeRates("c");

    const exchangeRates: Ref<NBPResponse> = ref({
      effectiveDate: ratingResponse.data[0].effectiveDate,
      rates: ratingResponse.data[0].rates,
      actionDate: new Date().toLocaleDateString(),
    });

    currencies.push("CAD");
    currencies.push("JPY");
    exchangeRates.value.rates = exchangeRates.value.rates.filter(
      (rate: ExchangeRate) => currencies.includes(rate.code)
    );

    return {
      t,
      locale,
      currency,
      displayCalculator,
      calculatorDescription,
      exchangeRates,
      transportCheckboxes,
      calculatorContent,
      activeState,
      activeCustomDuty,
      activeVatTax,
      carPrice,
      auctionCosts,
      basePrice,
      customDuty,
      vatValue,
      customSubTotal,
      activeTransportOptions,
      activeTransportCost,
      totalCost,
      convertCurrency,
      currenciesRates,
    };
  },
});
