<template>
  <section class="info__section w-full">
    <section
      class="info__content"
      v-for="section in sectionsDetails"
      :key="section.title"
    >
      <div class="image__wrapper">
        <img
          :data-src="
            require(`@/assets/images/backgrounds/${section.backgroundImageSmall}`)
          "
          :data-srcset="
            (require(`@/assets/images/backgrounds/${section.backgroundImageSmall}`) +
              ' 450w',
            require(`@/assets/images/backgrounds/${section.backgroundImage}`) +
              ' 768w')
          "
          class="unselectable lazyload"
          width="960"
          height="640"
          alt="section.title"
        />
      </div>
      <div
        class="info__description w-full h-full flex justify-center items-center"
      >
        <div class="info__description--content">
          <h1 class="h1" data-aos="zoom-y-out" data-aos-delay="100">
            {{ section.title }}
          </h1>
          <h2 class="h2" data-aos="zoom-y-out" data-aos-delay="600">
            {{ section.subTitle }}
          </h2>
          <hr data-aos="zoom-y-out" data-aos-delay="600" />
          <p data-aos="zoom-y-out" data-aos-delay="800">
            {{ section.description }}
          </p>
          <div
            class="button__wrapper"
            data-aos="zoom-y-out"
            data-aos-delay="800"
            v-if="section.buttonAction"
          >
            <a target="_blank" :href="section.buttonAction" rel="noopener"
              ><button type="button">{{ t("button") }}</button></a
            >
          </div>
        </div>
      </div>
    </section>
  </section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "InfoSection",
  props: {
    sectionsDetails: Array,
  },
  setup() {
    const { t } = useI18n({
      messages: {
        pl: {
          button: "Zobacz",
        },
        de: {
          button: "Suchen",
        },
        en: {
          button: "Search",
        },
      },
    });

    return { t };
  },
});
</script>

<style lang="scss">
.info__section .info__content {
  min-height: 40vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.4fr auto;
  @screen md {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
  @apply text-black bg-gray w-full max-w-full items-center justify-center;
  .image__wrapper {
    grid-row: 1;
    @screen lg {
      grid-column: 2;
    }
    @apply w-full h-full max-w-full relative;
    img {
      @apply w-full h-auto max-w-full object-cover;
      @media (min-width: 768px) and (min-height: 768px) {
        @apply h-full;
      }
    }
  }
  .info__description {
    .info__description--content {
      @apply py-xl px-lg md:py-2xl md:px-xl lg:px-3xl lg:py-4xl xl:px-4xl;
      h1 {
        @apply text-2xl ssm:text-4xl;
      }
      h2 {
        @apply mt-16;
      }
      hr {
        @apply mt-8;
      }
      p {
        @apply mt-4 text-black;
      }
      .button__wrapper {
        @apply mt-6 w-full flex items-center justify-center md:justify-start;
        button {
          @apply border-white text-white hover:bg-white hover:text-brown;
        }
      }
    }
  }

  &:nth-of-type(odd) {
    @apply text-white bg-brown;
  }
  &:nth-of-type(even) {
    .info__description {
      .info__description--content {
        h1 {
          @apply text-brown;
        }

        hr {
          @apply bg-brown;
        }

        .button__wrapper button {
          @apply border-brown text-black hover:bg-brown hover:text-white;
        }
      }
    }
    @screen lg {
      .info__description {
        grid-column: 2;
      }
      .image__wrapper {
        grid-column: 1;
      }
    }
  }
}
</style>
