
import { computed, defineComponent } from "vue";
import TheNavbar from "@/components/TheNavbar.vue";
import NavOverlay from "@/components/NavOverlay.vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "App",
  components: { TheNavbar, NavOverlay },
  setup() {
    const store = useStore();
    const isNavOpen = computed(() => store.getters.isNavOpen);

    return { isNavOpen };
  },
});
