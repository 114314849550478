
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import LanguagueSwitch from "@/components/LanguagueSwitch.vue";
import { AVAILABLE_LOCALES } from "@/main";

const messages = {
  pl: {
    cars: "To tylko auta?",
  },
  de: {
    cars: "Sind es nur Autos?",
  },
  en: {
    cars: "Is it only cars?",
  },
};

export default defineComponent({
  name: "Hero",
  components: { LanguagueSwitch },
  setup() {
    const { t } = useI18n({
      messages,
    });

    const carsMessage = computed(() => t("cars"));

    const store = useStore();
    const isNavOpen = computed(() => store.getters.isNavOpen);

    const vid = ref(null) as unknown as Ref<HTMLVideoElement>;

    const mobileMedia = window.matchMedia(
      "(max-width: 450px) and (max-height: 850px)"
    );

    /* eslint-disable @typescript-eslint/no-var-requires */
    onMounted(() => {
      const source = document.createElement("source");
      const sourceAlt = document.createElement("source");
      vid.value.poster = require(mobileMedia.matches
        ? "../assets/images/backgrounds/bg.webp"
        : "../assets/images/backgrounds/bg.webp");
      source.setAttribute(
        "src",
        require(mobileMedia.matches
          ? "../assets/videos/bgm.webm"
          : "../assets/videos/bg.webm")
      );

      sourceAlt.setAttribute(
        "src",
        require(mobileMedia.matches
          ? "../assets/videos/bgm.mp4"
          : "../assets/videos/bg.mp4")
      );

      vid.value.prepend(sourceAlt);
      vid.value.prepend(source);
      vid.value.play();
    });

    return {
      t,
      carsMessage,
      vid,
      isNavOpen,
      setNav: (nav: boolean) => store.commit("setNav", nav),
      AVAILABLE_LOCALES,
    };
  },
});
