
import { defineComponent, reactive, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";
import { useGtm } from "@gtm-support/vue-gtm";

interface FormState {
  name: string;
  email: string;
  message: string;
  fname: string;
  femail: string;
  fmessage: string;
  check: boolean;
}

const messages = {
  pl: {
    inputs: {
      name: {
        placeholder: "Imię i nazwisko",
        errors: {
          required: "Proszę uzupełnić opowiązkowe pole.",
        },
      },
      email: {
        placeholder: "Adres email",
        errors: {
          required: "Proszę uzupełnić opowiązkowe pole.",
          invalid: "Proszę podać prawidłowy adres email.",
        },
      },
      textarea: {
        placeholder: "Jak możemy pomóc?",
        errors: {
          required: "Proszę uzupełnić opowiązkowe pole.",
        },
      },
      submitButton: {
        status: {
          sent: "Wysłano",
          sending: "Przesyłanie...",
          send: "Wyślij wiadomość",
        },
      },
      response: "Odpiszemy niebawem",
    },
  },
  de: {
    inputs: {
      name: {
        placeholder: "Name und Nachname",
        errors: {
          required: "Bitte füllen Sie das Pflichtfeld aus.",
        },
      },
      email: {
        placeholder: "E-Mail-Adresse",
        errors: {
          required: "Bitte füllen Sie das Pflichtfeld aus.",
          invalid: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
        },
      },
      textarea: {
        placeholder: "Wie können wir helfen?",
        errors: {
          required: "Bitte füllen Sie das Pflichtfeld aus.",
        },
      },
      submitButton: {
        status: {
          sent: "Gesendet",
          sending: "Nachrichten...",
          send: "Schicken",
        },
      },
      response: "Wir werden bald antworten",
    },
  },
  en: {
    inputs: {
      name: {
        placeholder: "First and last name",
        errors: {
          required: "Please complete the mandatory field.",
        },
      },
      email: {
        placeholder: "E-mail address",
        errors: {
          required: "Please complete the mandatory field.",
          invalid: "Please enter a valid email address.",
        },
      },
      textarea: {
        placeholder: "How can we help?",
        errors: {
          required: "Please complete the mandatory field.",
        },
      },
      submitButton: {
        status: {
          sent: "Sent",
          sending: "Sending...",
          send: "Send a message",
        },
      },
      response: "We will reply soon",
    },
  },
};

export default defineComponent({
  name: "FormComponent",
  setup() {
    const { t } = useI18n({
      messages,
    });

    const state: FormState = reactive({
      name: "",
      email: "",
      message: "",
      fname: "", // prevents b users from spamming
      femail: "",
      fmessage: "",
      check: false,
    });

    const rules = {
      name: { required },
      email: { required, email },
      message: { required },
    };

    const v$ = useVuelidate(rules, state);

    let externalScript: HTMLScriptElement;
    const loadSMTP = () => {
      externalScript = document.createElement("script");
      externalScript.setAttribute("src", "https://smtpjs.com/v3/smtp.js");
      document.head.appendChild(externalScript);
    };

    loadSMTP();

    const gtm = useGtm();

    const emailLoading = ref(false);
    const isEmailSent = ref(false);
    const sendEmail = async () => {
      emailLoading.value = true;
      const isFormCorrect = await v$.value.$validate();
      if (
        !isFormCorrect ||
        state.fname ||
        state.femail ||
        state.fmessage ||
        state.check
      ) {
        emailLoading.value = false;
        return;
      }

      const host = process.env.VUE_APP_smtph;
      const uname = process.env.VUE_APP_smtpu;
      const pw = process.env.VUE_APP_smtpp;

      /* eslint-disable no-undef */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Email.send({
        // SecureToken: process.env.VUE_APP_smtp,
        Host: host,
        Username: uname,
        Password: pw,
        To: "info@dpvcars.pl",
        From: state.email,
        Subject: "Wiadomość ze strony internetowej.",
        Body: state.message,
      }).then((message: string) => {
        console.log(message);
        state.name = "";
        state.email = "";
        state.message = "";
        state.check = false;

        v$.value.$reset();
        isEmailSent.value = true;
        emailLoading.value = false;
        setTimeout(() => {
          isEmailSent.value = false;
        }, 5000);
      });

      gtm?.trackEvent({
        event: "interaction",
        category: "email",
        action: "click",
        label: "Email send button click",
        value: 5000,
        noninteraction: false,
      });
    };

    return { t, state, v$, sendEmail, emailLoading, isEmailSent };
  },
});
