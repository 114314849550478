<template>
  <TheNavbar />
  <transition name="navOverlay" mode="out-in">
    <NavOverlay v-if="isNavOpen" />
  </transition>
  <div class="overlay__mask" :class="{ visible: isNavOpen }"></div>
  <router-view />
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import TheNavbar from "@/components/TheNavbar.vue";
import NavOverlay from "@/components/NavOverlay.vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "App",
  components: { TheNavbar, NavOverlay },
  setup() {
    const store = useStore();
    const isNavOpen = computed(() => store.getters.isNavOpen);

    return { isNavOpen };
  },
});
</script>

<style lang="scss">
@media (min-width: 1280px) and (min-height: 500px) {
  .overlay__mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: 10;
    transition: background-color 0.3s ease-in-out 0.3s,
      opacity 0.3s ease-in-out 0.3s, visibility 0.3s ease-in-out 0.3s;
    &.visible {
      opacity: 1;
      visibility: visible;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
}
</style>
