export type Locale = "pl" | "de" | "en";

interface InitialState {
  isNavOpen: boolean;
  loading: boolean;
  overlayLoading: boolean;
  navTriggerValue: number;
  shouldLoadMaps: boolean;
  currentLocale: Locale;
}

const state: InitialState = {
  isNavOpen: false,
  loading: false,
  overlayLoading: true,
  navTriggerValue: 0,
  shouldLoadMaps: true,
  currentLocale: "pl",
};

const mutations = {
  setNav(state: InitialState, payload: boolean): void {
    state.isNavOpen = payload;
  },
  setLoading(state: InitialState, payload: boolean): void {
    state.loading = payload;
  },
  setOverlayLoading(state: InitialState, payload: boolean): void {
    state.overlayLoading = payload;
  },
  setNavTriggerValue(state: InitialState, payload: number): void {
    state.navTriggerValue = payload;
  },
  setLoadMaps(state: InitialState, payload: boolean): void {
    state.shouldLoadMaps = payload;
  },
  setCurrentLocale(state: InitialState, payload: Locale): void {
    state.currentLocale = payload;
  },
};

const getters = {
  isNavOpen(state: InitialState): boolean {
    return state.isNavOpen;
  },
  loading(state: InitialState): boolean {
    return state.loading;
  },
  overlayLoading(state: InitialState): boolean {
    return state.overlayLoading;
  },
  navTriggerValue(state: InitialState): number {
    return state.navTriggerValue;
  },
  shouldLoadMaps(state: InitialState): boolean {
    return state.shouldLoadMaps;
  },
  currentLocale(state: InitialState): Locale {
    return state.currentLocale;
  },
};

export default {
  state,
  mutations,
  getters,
};
