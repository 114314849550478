
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

const messages = {
  pl: {
    carOffers: {
      title: "Gdzie znajdziesz swój wymarzony pojazd?",
      subTitle: "Możemy to zrobić za Ciebie!",
      description:
        "Wyślij nam link do aukcji, która Cię intrestuje, a otrzymasz kalkulację kosztów i ocenę ryzyka zakupu danego egzemplarza.",
    },
    availableCars: {
      title: "Auta dostępne od ręki",
      description:
        "Sprawdź naszą pełną ofertę samochodów, które mamy dostępne na miejscu.",
      button: "Zobacz",
    },
  },
  de: {
    carOffers: {
      title: "Wo finden Sie Ihr Traumfahrzeug?",
      subTitle: "Wir können es für Sie tun!",
      description:
        "Senden Sie uns den Link zur Auktion, die Sie interessiert und erhalten Sie eine Kostenkalkulation und Risikobewertung für den Kauf des Exemplars.",
    },
    availableCars: {
      title: "Autos sofort verfügbar",
      description:
        "Schauen Sie sich unser gesamtes Angebot an Autos an, die wir vor Ort zur Verfügung haben.",
      button: "Suchen",
    },
  },
  en: {
    carOffers: {
      title: "Where do you find your dream car?",
      subTitle: "We can do it for you!",
      description:
        "Send us a link to an auction you are interested in and we will send you a quote and a risk assessment for the car you like.",
    },
    availableCars: {
      title: "Cars available now",
      description: "Check our inventory for cars we have on offer right now.",
      button: "Search",
    },
  },
};

export default defineComponent({
  name: "AboutCars",
  setup() {
    const { t } = useI18n({
      messages,
    });
    const brands = [
      {
        image: "IAA.svg",
        link: "https://iaai.com",
      },
      {
        image: "copart.svg",
        link: "https://copart.com",
      },
      {
        image: "impact.svg",
        link: "https://www.impactauto.ca/",
      },
      {
        image: "craigslist.svg",
        link: "https://craigslist.org",
      },
      {
        image: "ebay.svg",
        link: "https://ebay.com",
      },
    ];

    const carMarketUrl = "https://dpvcarspl.otomoto.pl";

    return { t, brands, carMarketUrl };
  },
});
