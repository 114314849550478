<template>
  <section class="aboutCars">
    <section class="carOffers">
      <div class="carOffers__container">
        <div class="carOffers__header">
          <h1 class="h1" data-aos="zoom-y-out" data-aos-delay="200">
            {{ t("carOffers.title") }}
          </h1>
          <div class="carOffers__header--items">
            <h2 class="h2" data-aos="zoom-y-out" data-aos-delay="200">
              {{ t("carOffers.subTitle") }}
            </h2>
            <hr data-aos="zoom-y-out" data-aos-delay="400" />
            <p data-aos="zoom-y-out" data-aos-delay="400">
              {{ t("carOffers.description") }}
            </p>
          </div>
        </div>

        <div class="brands">
          <div
            class="brand__wrapper"
            data-aos="zoom-y-out"
            data-aos-delay="600"
          >
            <div class="brand" v-for="brand in brands" :key="brand.image">
              <a :href="brand.link" target="_blank" rel="noopener">
                <img
                  :data-src="require(`../assets/images/brands/${brand.image}`)"
                  width="100"
                  height="48"
                  class="unselectable lazyload"
                  :alt="brand.image"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="availableCars">
      <div class="availableCars__container">
        <div class="availableCars__description">
          <h2 class="h2" data-aos="zoom-y-out" data-aos-delay="400">
            {{ t("availableCars.title") }}
          </h2>
          <hr data-aos="zoom-y-out" data-aos-delay="400" />
          <p data-aos="zoom-y-out" data-aos-delay="800">
            {{ t("availableCars.description") }}
          </p>
          <div
            class="button__wrapper"
            data-aos="zoom-y-out"
            data-aos-delay="800"
          >
            <a target="_blank" :href="carMarketUrl" rel="noopener"
              ><button type="button">{{ t("availableCars.button") }}</button></a
            >
          </div>
        </div>
        <div
          class="availableCars__image--wrapper"
          data-aos="zoom-y-out"
          data-aos-delay="800"
        >
          <img
            :data-src="require('../assets/images/backgrounds/cars@450w.webp')"
            :data-srcset="
              (require('../assets/images/backgrounds/cars@450w.webp') + ' 450w',
              require('../assets/images/backgrounds/cars.webp'))
            "
            alt="cars"
            width="600"
            height="300"
            class="lazyload unselectable"
          />
        </div>
      </div>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

const messages = {
  pl: {
    carOffers: {
      title: "Gdzie znajdziesz swój wymarzony pojazd?",
      subTitle: "Możemy to zrobić za Ciebie!",
      description:
        "Wyślij nam link do aukcji, która Cię intrestuje, a otrzymasz kalkulację kosztów i ocenę ryzyka zakupu danego egzemplarza.",
    },
    availableCars: {
      title: "Auta dostępne od ręki",
      description:
        "Sprawdź naszą pełną ofertę samochodów, które mamy dostępne na miejscu.",
      button: "Zobacz",
    },
  },
  de: {
    carOffers: {
      title: "Wo finden Sie Ihr Traumfahrzeug?",
      subTitle: "Wir können es für Sie tun!",
      description:
        "Senden Sie uns den Link zur Auktion, die Sie interessiert und erhalten Sie eine Kostenkalkulation und Risikobewertung für den Kauf des Exemplars.",
    },
    availableCars: {
      title: "Autos sofort verfügbar",
      description:
        "Schauen Sie sich unser gesamtes Angebot an Autos an, die wir vor Ort zur Verfügung haben.",
      button: "Suchen",
    },
  },
  en: {
    carOffers: {
      title: "Where do you find your dream car?",
      subTitle: "We can do it for you!",
      description:
        "Send us a link to an auction you are interested in and we will send you a quote and a risk assessment for the car you like.",
    },
    availableCars: {
      title: "Cars available now",
      description: "Check our inventory for cars we have on offer right now.",
      button: "Search",
    },
  },
};

export default defineComponent({
  name: "AboutCars",
  setup() {
    const { t } = useI18n({
      messages,
    });
    const brands = [
      {
        image: "IAA.svg",
        link: "https://iaai.com",
      },
      {
        image: "copart.svg",
        link: "https://copart.com",
      },
      {
        image: "impact.svg",
        link: "https://www.impactauto.ca/",
      },
      {
        image: "craigslist.svg",
        link: "https://craigslist.org",
      },
      {
        image: "ebay.svg",
        link: "https://ebay.com",
      },
    ];

    const carMarketUrl = "https://dpvcarspl.otomoto.pl";

    return { t, brands, carMarketUrl };
  },
});
</script>
<style lang="scss">
.aboutCars {
  z-index: 2;
  @apply relative bg-gray;
  .fullSizeBackground {
    background: transparent;
    // background-image: url("../assets/images/backgrounds/amg.png");
    @apply w-full h-96 bg-center bg-no-repeat bg-cover bg-fixed;
  }

  .carOffers {
    @apply bg-gray py-2xl px-lg md:px-xl lg:px-3xl lg:py-4xl;
    .carOffers__container {
      @apply max-w-6xl m-auto;
      .carOffers__header {
        grid-template-rows: repeat(2, auto);

        @screen lg {
          grid-template-rows: auto;
          grid-template-columns: repeat(2, 1fr);
        }
        @apply grid lg:gap-x-16 xl:gap-x-32 justify-between items-start lg:flex-row mb-0 lg:mb-24;
        h1 {
          @apply text-brown lg:text-4xl;
        }
        .carOffers__header--items {
          @apply mt-12 lg:mt-0;
          h2 {
            @apply text-black;
          }
          hr {
            @apply mt-8 bg-brown;
          }
          p {
            @apply mt-4 mb-12 lg:mb-0 text-black;
          }
        }
      }
      .brands {
        .brand__wrapper {
          @apply mx-auto grid gap-y-12 gap-x-2 md:gap-2 grid-cols-4 md:grid-cols-5;
          .brand {
            @apply flex items-center justify-center col-span-2 md:col-auto;
            &:last-of-type {
              @apply col-start-2 col-end-4 md:col-auto;
            }
            img {
              opacity: 0.3;
              transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1);
              &:hover {
                opacity: 1;
              }
              height: 48px;
              @apply max-h-12;
            }
          }
        }
      }
    }
  }
  .availableCars {
    @apply bg-gray pb-2xl px-lg md:px-xl lg:px-3xl lg:py-4xl lg:pt-2xl;
    .availableCars__container {
      grid-template-columns: 1fr;
      grid-template-rows: 0.3fr auto;
      @screen md {
        background-image: url("../assets/images/backgrounds/stripes.svg");
      }
      @apply grid max-w-6xl m-auto bg-no-repeat;
      .availableCars__description {
        @apply mt-12 flex items-start justify-center flex-col;
        grid-row: 2;
        h2 {
          @apply text-black;
        }
        hr {
          @apply mt-8 bg-brown;
        }
        p {
          @apply mt-4 mb-16 text-black max-w-md;
        }
        .button__wrapper {
          @apply w-full flex items-center justify-start;
          button {
            @apply border-brown text-black hover:bg-brown hover:text-white;
          }
        }
      }

      @screen md {
        .availableCars__description {
          grid-row: 1;
          @apply mt-0;
        }
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        .availableCars__description {
        }
      }
      @apply w-full items-center justify-center;
      .availableCars__image--wrapper {
        @apply w-full h-full relative;
        img {
          @apply w-full h-auto object-contain max-w-full;
        }
      }
    }
  }
}
</style>
