<template>
  <section class="importSection">
    <div class="importSection__container">
      <div class="importSection__header">
        <h1 class="h1" data-aos="zoom-y-out" data-aos-delay="100">Import</h1>
        <hr data-aos="zoom-y-out" data-aos-delay="100" />
        <h2 class="h2" data-aos="zoom-y-out" data-aos-delay="100">
          {{ t("start") }}
        </h2>
      </div>
      <div class="importSection__grid">
        <div
          class="grid__item"
          v-for="(item, i) in gridItems"
          :key="item.title"
        >
          <div class="number__wrapper">
            <div class="item__number" data-aos="fade" data-aos-delay="100">
              {{ i + 1 }}
            </div>
          </div>
          <div class="image__wrapper" data-aos="fade" data-aos-delay="400">
            <div
              class="image__content flex items-center justify-center"
              data-aos="zoom-y-out"
              data-aos-delay="400"
            >
              <img
                :data-src="
                  require(`@/assets/images/importSection/${item.image}`)
                "
                :alt="item.title"
                width="150"
                height="120"
                class="unselectable lazyload"
              />
            </div>
          </div>
          <div class="item__details" data-aos="fade" data-aos-delay="400">
            <h2 class="h2" data-aos="zoom-y-out" data-aos-delay="600">
              {{ item.title }}
            </h2>
            <hr data-aos="zoom-y-out" data-aos-delay="600" />
            <p data-aos="zoom-y-out" data-aos-delay="600">
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

const messages = {
  pl: {
    start: "Od czego zacząć?",
    gridItems: [
      {
        title: "Wybór pojazdu",
        description:
          "Pomagamy znaleźć samochód według wybranych przez Ciebie parametrów, sprawdzamy historię pojazdu i przygotowujemy kalkulację kosztów importu wprost pod Twój dom. Naszym celem jest pomoc w doborze konkretnego pojazdu, określenie stopnia uszkodzenia – w przypadku licytacji aut powypadkowych oraz przedstawienie prywatnych ofert sprzedaży aut bezwypadkowych.",
      },
      {
        title: "Umowa",
        description:
          "Po znalezieniu konkretnego egzemplarza podpisujemy umowę, ustalamy wszystkie detale licytacji jak np. maksymalna kwota do której licytujemy pojazd tak aby zmieścić się w Twoim budżecie. Pobieramy depozyt w wysokości 10% zakładanego budżetu zakupu pojazdu – Ta kwota zabezpiecza nas w przypadku nie odebrania wylicytowanego pojazdu, jest to odgórna kara umowna portali aukcyjnych. W przypadku niewylicytowania pojazdu zaliczka jest zwracana niezwłocznie na Twoje konto.",
      },
      {
        title: "Wygrana licytacja",
        description:
          "Po wygranej licytacji opłacasz wygrany pojazd w przeciągu 3 dni roboczych.",
      },
      {
        title: "Transport",
        description:
          "Po otrzymaniu wpłaty odbieramy pojazd z placu aukcyjnego i wysyłamy go do Europy – opłata za transport na terenie USA / Kanady i fracht morski do portu w Europie. Otrzymasz numer kontenera aby być na bieżąco z położeniem statku na którym się znajduje.",
      },
      {
        title: "Procedury celno-skarbowe",
        description:
          "Po przybyciu auta do portu w EU zajmujemy się odprawą. Opłacasz CŁO, VAT i opłaty portowe. Po zaksięgowaniu wpłat zlecamy transport.",
      },
      {
        title: "Dostawa pojazdu",
        description:
          "Dostarczamy pojazd pod wybrane przez Ciebie miejsce w Polsce lub Europie.",
      },
      {
        title: "Naprawa",
        description:
          "Dodatkowo specjalnie na Twoje życzenie, oferujemy Ci naprawę auta. Dzięki stałej współpracy z hurtowniami i warsztatami, ceny dla naszych klientów będą o wiele niższe. Twoim zadaniem będzie tylko czekać, aż Twój wymarzony pojazd dostarczymy w wybrane przez Ciebie miejsce.",
      },
    ],
  },
  de: {
    start: "Wie fängt man an?",
    gridItems: [
      {
        title: "Fahrzeugauswahl",
        description:
          "Wir helfen Ihnen, ein Auto nach den von Ihnen gewählten Parametern zu finden, überprüfen die Fahrzeughistorie und erstellen eine Berechnung der Importkosten mit Lieferung direkt zu Ihnen nach Hause. Unser Ziel ist es, bei der Auswahl eines bestimmten Fahrzeuges zu helfen, den Schadensgrad -  im Fall einer Versteigerung unfallbeschädigter Autos - zu ermitteln und private Angebote für den Verkauf unfallfreier Autos zu unterbreiten.",
      },
      {
        title: "Vertrag",
        description:
          "Nachdem wir ein bestimmtes Exemplar gefunden haben, legen wir alle Details der Auktion fest. Zum Beispiel den Höchstbetrag, bis zu dem wir das Fahrzeug versteigern können, damit es Ihrem Budget entspricht und unterzeichnen wir einen Vertrag.",
      },
      {
        title: "Gewonnene Auktion",
        description:
          "Nach dem Gewinn der Versteigerung bezahlen Sie das gewonnene Auto innerhalb von 3 Werktagen.",
      },
      {
        title: "Transport",
        description:
          "Nach Zahlungseingang holen wir das Fahrzeug vor Ort ab und versenden es nach Europa - Zahlung für Transport in USA / Kanada und Seefracht zu einem Hafen in Europa. Sie erhalten eine Containernummer, um die Position des Schiffes überprüfen zu können, auf dem sich das Fahrzeug befindet",
      },
      {
        title: "Zoll- und Steuerverfahren",
        description:
          "Nachdem das Auto im EU-Hafen angekommen ist, kümmern wir uns um die Zollabfertigung. Sie bezahlen Zölle, Mehrwertsteuer und Hafengebühren. Nachdem die Zahlungen gebucht sind, beschäftigen wir uns mit der Lieferung.",
      },
      {
        title: "Fahrzeugübergabe",
        description:
          "Wir liefern das Fahrzeug an den Ort Ihrer Wahl in Deutschland oder Europa.",
      },
      {
        title: "Reparatur",
        description:
          "Weiterhin bieten wir Ihnen  speziell auf Ihren Wunsch Autoreparaturen an. Dank der ständigen Zusammenarbeit mit Großhändlern und Werkstätten werden die Preise für unsere Kunden viel niedriger sein. Ihre Aufgabe ist es nur, darauf zu warten, dass Ihr Traumfahrzeug an den Ort Ihrer Wahl geliefert wird.",
      },
    ],
  },
  en: {
    start: "Where to start?",
    gridItems: [
      {
        title: "Choosing the car",
        description:
          "We help you find a car according to your needs and budget. We check the car's history and prepare a cost estimate of a car delivered to your doorstep. Our aim is to find the best possible example from damaged cars (with an estimate of repair costs) as well as private offers of clean title cars.",
      },
      {
        title: "Agreement",
        description:
          "After finding the car you desire, we sign the agreement. Obviously we will set the terms first, like the price you are willing to pay on the auction. We take an advance of 10% of the budget for your car. It’s a safety net for us, in the unlikely case in which the client fails to pay for the auctioned car. Auction sites set a penalty for such an event and it’s 10% of the car's value. Should we fail to win the auction, the advance will be returned to your account promptly.",
      },
      {
        title: "Successful Auction",
        description:
          "After a successful bid, you pay for the car within 3 working days.",
      },
      {
        title: "Transportation",
        description:
          "After we receive the payment, we pick the car up from the auction lot and send it to Europe – here we pay the transportation fee from USA / Canada to Europe via sea. You will receive the container number so you can be up to speed on the cars location.",
      },
      {
        title: "Customs and Tax Procedures",
        description:
          "After the car is safely delivered to Europe we will deal with taxes and customs. You will have to pay VAT, Customs fee and shipping cost. After that we organise transportation to a location of your choice.",
      },
      {
        title: "Delivery",
        description:
          "We will deliver the car to a location of your choice in Europe.",
      },
      {
        title: "Repair",
        description:
          "Additionally we can offer a full and professional repair of your car. We constantly trade with car parts wholesalers and garages thus can offer competitive prices for parts and labour. All you have to do is wait comfortably and we will deliver your dream car to your doorstep.",
      },
    ],
  },
};

export default defineComponent({
  name: "Import",
  setup() {
    const { t } = useI18n({
      messages,
    });
    const gridItems = computed(() => [
      {
        title: t("gridItems[0].title"),
        description: t("gridItems[0].description"),
        image: "car.png",
      },
      {
        title: t("gridItems[1].title"),
        description: t("gridItems[1].description"),
        image: "contract.png",
      },
      {
        title: t("gridItems[2].title"),
        description: t("gridItems[2].description"),
        image: "auction.png",
      },
      {
        title: t("gridItems[3].title"),
        description: t("gridItems[3].description"),
        image: "transport.png",
      },
      {
        title: t("gridItems[4].title"),
        description: t("gridItems[4].description"),
        image: "procedures.png",
      },
      {
        title: t("gridItems[5].title"),
        description: t("gridItems[5].description"),
        image: "delivery.png",
      },
      {
        title: t("gridItems[6].title"),
        description: t("gridItems[6].description"),
        image: "repair.png",
      },
    ]);

    return { t, gridItems };
  },
});
</script>
<style lang="scss">
.importSection {
  @apply w-full bg-gray;
  .importSection__container {
    @apply bg-gray text-black py-2xl pb-sm px-lg md:px-xl lg:px-3xl flex items-center flex-col;
    .importSection__header {
      @apply flex flex-col items-center justify-center;
      hr {
        @apply mt-8;
      }
      h2 {
        @apply my-4 uppercase font-normal;
      }
    }
    .importSection__grid {
      @apply w-full pt-xl max-w-6xl;
      .grid__item {
        z-index: 1;
        display: grid;
        grid-template-rows: minmax(10vw, 15vw) 0.7fr;
        grid-template-columns: auto;
        @apply my-12 gap-y-8 relative text-center;
        @screen md {
          grid-template-rows: 1fr;
          grid-template-columns: repeat(2, 1fr);
          @apply my-0 gap-y-0;
        }
        .number__wrapper {
          transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1);
          .item__number {
            z-index: 2;
            @apply w-16 h-16 bg-brown text-white text-2xl font-bold rounded-full flex items-center justify-center absolute;
            @screen md {
              left: 22%;
              @apply -top-8;
            }
            transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1),
              background-color 0.3s cubic-bezier(0.76, 0, 0.24, 1),
              opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1);
            &:hover {
              transform: scale(1.05);
              @apply bg-black;
            }
          }
        }
        .image__wrapper {
          max-height: 60vh;

          @apply w-full h-full flex items-center justify-center relative;

          img {
            object-fit: contain;
            max-height: 15vh;
            transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1),
              background 0.3s cubic-bezier(0.76, 0, 0.24, 1);
            &:hover {
              transform: scale(1.05);
            }
          }
          @screen md {
            grid-column: 2;
            grid-row: 1;
            border-left-width: 1px;
            @apply px-lg py-2xl border-brown;
            img {
              max-height: 10vh;
            }
          }
        }
        .item__details {
          @apply flex  justify-center items-center flex-col;
          @screen md {
            grid-column: 1;
            grid-row: 1;
            border-right-width: 1px;
            @apply border-brown py-2xl px-xl relative;
            &::after {
              content: "";
              height: 2px;
              @apply absolute top-0 left-auto right-0 w-1/2 bg-brown;
            }
          }
          hr {
            @apply bg-brown mt-8;
          }
          p {
            @apply mt-4 text-center;
          }
        }
        &:nth-of-type(even) {
          @screen md {
            .item__number {
              left: auto;
              right: 22%;
            }
            .item__details {
              grid-column: 2;
              border-right-width: 0;
              border-left-width: 1px;
              &::after {
                @apply absolute top-0 left-0 right-auto w-1/2 h-0.5 bg-brown;
              }
            }
            .image__wrapper {
              grid-column: 1;
              border-left-width: 0;
              border-right-width: 1px;
            }
          }
        }
        &:last-of-type {
          @screen md {
            .item__details,
            .image__wrapper {
              border-left-width: 0;
              border-right-width: 0;
            }
          }
        }
      }
    }
  }
}
</style>
