<template>
  <header class="hero">
    <video
      id="video"
      class="heroVideo"
      playsinline
      autoplay
      muted
      loop
      preload="metadata"
      ref="vid"
    ></video>
  </header>
  <div class="hero__container">
    <nav class="static__nav">
      <div class="back__wrapper" data-aos="fade-up" data-aos-delay="800">
        <LanguagueSwitch :locales="AVAILABLE_LOCALES" theme="light" />
      </div>
      <div class="logo" data-aos="fade-up" data-aos-delay="800">
        <img
          src="@/assets/images/logoLight.svg"
          class="unselectable"
          alt="mainLogo"
        />
      </div>
      <div class="burger__wrapper" data-aos="fade-up" data-aos-delay="800">
        <div
          class="nav-mobile"
          id="nav-icon-static"
          :class="{ open: isNavOpen }"
          @click="setNav(!isNavOpen)"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>
    <div class="hero__content" data-aos="fade-up" data-aos-delay="2000">
      <h1 class="hero__title">{{ carsMessage }}</h1>
      <img
        src="../assets/images/icons/arrow.png"
        alt="arrow"
        class="arrow__icon unselectable"
        v-scroll-to="'.aboutUs'"
      />
      <ul class="social__icons">
        <li>
          <a
            href="https://www.facebook.com/DPVcars/"
            target="_blank"
            class="socialIcon"
            aria-label="Instagram"
            rel="noopener"
          >
            <svg
              class="svgIcon"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z"
              />
            </svg>
          </a>
        </li>
        <li class="ml-4">
          <a
            href="https://www.instagram.com/dpvcarspl"
            target="_blank"
            class="socialIcon"
            aria-label="Instagram"
            rel="noopener"
          >
            <svg
              class="svgIcon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-6 -6 36 36"
            >
              <path
                d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
              />
            </svg>
          </a>
        </li>
        <li class="ml-4">
          <a
            href="https://www.youtube.com/channel/UCiYdsGnBqPKE0NzUexm01fg"
            target="_blank"
            class="socialIcon"
            aria-label="Youtube"
            rel="noopener"
          >
            <svg
              class="svgIcon items-center m-auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-6 -6 36 36"
            >
              <path
                d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
              />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import LanguagueSwitch from "@/components/LanguagueSwitch.vue";
import { AVAILABLE_LOCALES } from "@/main";

const messages = {
  pl: {
    cars: "To tylko auta?",
  },
  de: {
    cars: "Sind es nur Autos?",
  },
  en: {
    cars: "Is it only cars?",
  },
};

export default defineComponent({
  name: "Hero",
  components: { LanguagueSwitch },
  setup() {
    const { t } = useI18n({
      messages,
    });

    const carsMessage = computed(() => t("cars"));

    const store = useStore();
    const isNavOpen = computed(() => store.getters.isNavOpen);

    const vid = ref(null) as unknown as Ref<HTMLVideoElement>;

    const mobileMedia = window.matchMedia(
      "(max-width: 450px) and (max-height: 850px)"
    );

    /* eslint-disable @typescript-eslint/no-var-requires */
    onMounted(() => {
      const source = document.createElement("source");
      const sourceAlt = document.createElement("source");
      vid.value.poster = require(mobileMedia.matches
        ? "../assets/images/backgrounds/bg.webp"
        : "../assets/images/backgrounds/bg.webp");
      source.setAttribute(
        "src",
        require(mobileMedia.matches
          ? "../assets/videos/bgm.webm"
          : "../assets/videos/bg.webm")
      );

      sourceAlt.setAttribute(
        "src",
        require(mobileMedia.matches
          ? "../assets/videos/bgm.mp4"
          : "../assets/videos/bg.mp4")
      );

      vid.value.prepend(sourceAlt);
      vid.value.prepend(source);
      vid.value.play();
    });

    return {
      t,
      carsMessage,
      vid,
      isNavOpen,
      setNav: (nav: boolean) => store.commit("setNav", nav),
      AVAILABLE_LOCALES,
    };
  },
});
</script>

<style lang="scss">
@mixin coverer {
  @apply w-full h-screen absolute top-0 left-0;
}
.hero {
  :root {
    --headerOpacity: 1;
    --headerScale: 1;
  }
  z-index: -1;
  @apply w-full h-screen top-0 bg-white absolute text-center;
  &,
  video {
    @include coverer;
  }

  video {
    @apply bg-brown object-cover;
  }

  img {
    @apply w-full h-full;
  }

  .viewport-header {
    @apply flex items-center justify-center opacity-100;
    opacity: var(--headerOpacity);
    transform: scale(var(--headerScale));
  }
}

.hero__container {
  @include coverer;
  @apply flex items-center flex-col;
  z-index: 1;
  .static__nav {
    min-height: 8vh;
    padding-left: 6vw;
    padding-right: 6vw;
    @apply py-lg md:py-xl lg:px-4xl w-full flex justify-between items-center;
    .back__wrapper {
      @apply flex justify-start items-center;
      flex: 1;
    }
    .logo {
      max-width: 40vw;
      max-height: 8vh;
      @apply bg-center bg-cover bg-no-repeat flex items-center justify-center text-center flex-row flex-1;
      img {
        @apply w-full h-full object-contain max-w-full max-h-full;
      }
    }
    .burger__wrapper {
      @apply h-full flex flex-1 justify-end items-center;
      #nav-icon-static span {
        @apply bg-white;
      }
    }

    @media (min-width: 360px) {
      .logo {
        max-width: 35vw;
      }
    }

    @media (min-width: 450px) {
      .logo {
        max-width: 30vw;
      }
    }
    @media (min-width: 768px) {
      .logo {
        max-width: 25vw;
      }
    }
    @media (max-width: 850px) and (max-height: 450px) and (orientation: landscape) {
      .logo {
        max-width: 30vw;
        max-height: 10vh;
      }
    }
    @media (min-width: 1024px) {
      .logo {
        max-width: 20vw;
      }
    }
    @media (min-width: 1280px) {
      .logo {
        max-width: 15vw;
      }
    }
  }
  .hero__content {
    @apply relative w-full h-full p-xl pb-4xl lg:p-4xl lg:pb-6xl xl:pb-4xl flex items-end justify-center lg:justify-between;
    .hero__title {
      font-size: 6vmin;
      line-height: 0.9em;
      letter-spacing: -0.035em;
      @apply text-white font-bold;
    }
    .arrow__icon {
      left: 50%;
      transform: translate(-50%, 0);
      @apply absolute bottom-0 mb-16 md:mb-8 w-12 h-12 fill-current text-white cursor-pointer;
    }
    .social__icons {
      @apply hidden lg:flex flex-col;
      li {
        @apply ml-4 lg:mb-4 lg:ml-0;
        &:last-of-type {
          @apply lg:mb-0;
        }
      }
    }
  }
}
</style>
