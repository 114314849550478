<template>
  <section class="overlay" ref="overlay">
    <div class="overlay__container">
      <div class="overlay__wrapper">
        <ul class="navItems">
          <li
            class="item"
            @click="setNav(false)"
            v-for="item in navItems"
            :key="item.section"
          >
            <p v-scroll-to="{ el: item.section, onDone: onScrollDone }">
              {{ item.title }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
} from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

interface NavItem {
  title: string;
  section: string;
}

const messages = {
  pl: {
    navItems: [
      {
        title: "O Nas",
      },
      {
        title: "Import",
      },
      {
        title: "Oferta",
      },
      {
        title: "Aktualności",
      },
      {
        title: "Kalkulator",
      },
      {
        title: "Warsztat",
      },
      {
        title: "Finansowanie",
      },
      {
        title: "Kontakt",
      },
    ],
  },
  de: {
    navItems: [
      {
        title: "Über uns",
      },
      {
        title: "Import",
      },
      {
        title: "Offerte",
      },
      {
        title: "Nachrichten",
      },
      {
        title: "Rechner",
      },
      {
        title: "Werkstatt",
      },
      {
        title: "Finanzierung",
      },
      {
        title: "Kontakt",
      },
    ],
  },
  en: {
    navItems: [
      {
        title: "About us",
      },
      {
        title: "Import",
      },
      {
        title: "Offer",
      },
      {
        title: "News",
      },
      {
        title: "Calculator",
      },
      {
        title: "Garage",
      },
      {
        title: "Financing",
      },
      {
        title: "Contact",
      },
    ],
  },
};

export default defineComponent({
  name: "NavOverlay",
  setup() {
    const { t } = useI18n({
      messages,
    });

    const store = useStore();
    const navItems: ComputedRef<NavItem[]> = computed(() => [
      {
        title: t("navItems[0].title"),
        section: ".aboutUs",
      },
      {
        title: t("navItems[1].title"),
        section: ".importSection",
      },
      {
        title: t("navItems[2].title"),
        section: ".aboutCars",
      },
      {
        title: t("navItems[3].title"),
        section: ".news",
      },
      {
        title: t("navItems[4].title"),
        section: ".pricingCalculator",
      },
      {
        title: t("navItems[5].title"),
        section: ".info__section > .info__content:first-of-type",
      },
      {
        title: t("navItems[6].title"),
        section: ".info__section > .info__content:last-of-type",
      },
      {
        title: t("navItems[7].title"),
        section: ".contact",
      },
    ]);

    const setNav = (nav: boolean) => store.commit("setNav", nav);

    const onScrollDone = () => {
      setNav(false);
    };

    const overlay: Ref<HTMLElement | null> = ref(null);

    const handleOverlayClick = (e: MouseEvent) => {
      if (
        overlay.value &&
        (overlay.value as HTMLElement).isSameNode(e.target as Node)
      ) {
        setNav(false);
      }
    };

    onMounted(() => {
      ((overlay.value as unknown) as HTMLElement).addEventListener(
        "click",
        handleOverlayClick
      );
    });

    onBeforeUnmount(() => {
      ((overlay.value as unknown) as HTMLElement).removeEventListener(
        "click",
        handleOverlayClick
      );
    });

    return {
      navItems,
      setNav,
      onScrollDone,
      overlay,
    };
  },
});
</script>
<style lang="scss">
@import "@/assets/styles/global.scss";
// enter/leave overlay animation
.navOverlay-enter-active,
.navOverlay-leave-active {
  transition: transform 1s cubic-bezier(0.65, 0, 0.35, 1);
}

.navOverlay-enter-from,
.navOverlay-leave-to {
  transform: translateY(100%);
}

.navOverlay-enter-to,
.navOverlay-leave {
  transform: translateY(0);
}

$verticalPadding: 4vh;
$horizontalPadding: 6vw;
.overlay {
  position: fixed;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  .overlay__container {
    @apply w-full h-full flex items-center justify-center bg-brown text-black;
    transition: background-color 0.3s cubic-bezier(0.77, 0, 0.175, 1),
      color 0.3s cubic-bezier(0.77, 0, 0.175, 1);
    .overlay__wrapper {
      padding: $verticalPadding * 2 4vh 4vh 4vh;
      @apply flex items-center justify-center flex-col;
      .burger__wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;
        height: 100%;
      }
      .navItems {
        padding: $verticalPadding / 2 0;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: $verticalPadding / 8;
        @media (min-height: 700px) {
          row-gap: $verticalPadding / 2;
        }
        width: max-content;
        margin: 0 auto;
        .item {
          line-height: 1.5;
          p {
            @apply inline-block cursor-pointer text-3xl lg:text-4xl font-bold hover:text-white uppercase;
            line-height: 1.5;
          }
        }
        .socials__container {
          @apply flex items-center;
          justify-content: flex-start;
          flex-direction: row;
          > a {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            padding: 1vh;
            transition: background-color 0.2s linear 0s;
            margin: 0 10px;
            &:hover {
              background-color: rgba(255, 255, 255, 0.1);
            }
            &:first-of-type {
              margin-left: -1vh;
            }
            &:last-of-type {
              margin-right: 0;
            }

            img {
              width: 28px;
              height: 28px;
            }
          }
        }
      }
    }

    @media (min-width: 360px) {
      .overlay__wrapper .navItems .item {
        font-size: 2rem;
      }
    }

    @media (min-width: 500px) {
      .overlay__wrapper .account__preview {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2vh;
        .account__avatar--wrapper {
          padding-bottom: 4vh;
          .account__avatar {
            width: 16vh;
            height: 16vh;
          }
        }
      }
    }

    @media (max-width: 850px) and (max-height: 450px) and (orientation: landscape) {
      .overlay__wrapper {
        padding-top: $verticalPadding * 4;
        .account__preview {
          display: none;
        }
        .navItems {
          grid-template-columns: repeat(2, 1fr);
          column-gap: $horizontalPadding;
          row-gap: $verticalPadding;
          .item {
            font-size: 2.25rem;
          }
        }
      }
    }
    @media (min-width: 800px) and (max-width: 850px) and (max-height: 400px) and (orientation: landscape) {
      .overlay__wrapper .navItems .item {
        font-size: 1.75rem;
      }
    }
    @media (max-width: 750px) and (max-height: 450px) and (orientation: landscape) {
      .overlay__wrapper .navItems .item {
        font-size: 2rem;
      }
    }
    @media (max-width: 650px) and (max-height: 450px) and (orientation: landscape) {
      .overlay__wrapper .navItems {
        row-gap: 4vh;
        .item {
          font-size: 1.75rem;
        }
      }
    }
  }
}
@media (min-width: 1280px) and (min-height: 500px) {
  .overlay {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .overlay__container {
      width: 100%;
      z-index: 0;
      grid-column: 2;
      .overlay__wrapper {
        align-items: flex-start;
        padding-left: 8vw;
        padding-right: 8vw;
        .navItems {
          .item {
            text-align: left;
            &:not(.socials__container) {
              display: inline;
              span {
                position: relative;
                &:hover:after {
                  width: 100%;
                }
                &::after {
                  content: "";
                  display: block;
                  position: absolute;
                  left: 0px;
                  background-color: white;
                  height: 0.2rem;
                  margin-top: 0.2rem;
                  transition: width 0.5s cubic-bezier(0.76, 0, 0.24, 1);
                  width: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .navOverlay-enter-from,
  .navOverlay-leave-to {
    transform: translateX(100%);
  }

  .navOverlay-enter-to,
  .navOverlay-leave {
    transform: translateX(0);
  }
}
@media (min-width: 1650px) and (min-height: 500px) {
  .overlay .overlay__container .overlay__wrapper .navItems .item {
    font-size: 2.75rem;
    &.socials__container > a img {
      width: 32px;
      height: 32px;
    }
  }
}
</style>
