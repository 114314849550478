<template>
  <div
    class="languagueSwitch__container flex flex-row justify-center items-center"
  >
    <img
      class="flag"
      :src="require(`../assets/images/icons/${currentLocale}.svg`)"
      :alt="currentLocale"
    />
    <select
      :value="currentLocale"
      @change="handleLocaleChange"
      name="languagueSwitch"
      id="languagueSwitch"
      :class="theme === 'dark' ? 'dark' : 'light'"
    >
      <option
        :value="locale.shortName"
        v-for="locale in locales"
        :key="locale.shortName"
      >
        {{ locale.name }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { chosenLocale } from "@/main";
import { computed, defineComponent, PropType, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

interface Locale {
  flag: string;
  shortName: string;
  name: string;
}

export default defineComponent({
  name: "LanguagueSwitch",
  props: {
    locales: {
      type: Array as PropType<Locale[]>,
      required: true,
    },
    theme: {
      type: String as PropType<"dark" | "light">,
      required: true,
    },
  },
  setup(props) {
    const { locale } = useI18n({ useScope: "global" });
    const store = useStore();

    const currentLocale = computed(() => store.getters.currentLocale);

    const setCurrentLocale = (locale: string) => {
      store.commit("setCurrentLocale", locale);
    };

    const handleLocaleChange = (event: { target: HTMLInputElement }) => {
      setCurrentLocale(event.target.value);
    };

    setCurrentLocale(
      props.locales
        .map((locale) => locale.shortName)
        .find((locale) => locale === chosenLocale) || props.locales[0].shortName
    );

    watch(currentLocale, (newLocale) => {
      locale.value = newLocale;
      setCurrentLocale(newLocale);
    });

    return { currentLocale, handleLocaleChange };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/global.scss";

.languagueSwitch__container {
  .flag {
    @apply hidden md:block w-8 h-6 mr-2;
  }
  select {
    background-color: transparent;
    padding: 6px 0px;
    @apply border-none outline-none text-black w-16 text-sm ssm:text-base font-bold;

    option {
      -webkit-appearance: none;
      @apply p-sm;
    }

    &.dark {
      @apply text-black;
    }
    &.light {
      @apply text-white;
      option {
        @apply bg-secondaryBlack;
      }
    }

    @media (min-width: 360px) {
      @apply w-min;
    }
  }
}
</style>
