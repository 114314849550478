<template>
  <section class="home">
    <Hero />
    <main>
      <AboutUs />
      <ImportSection />
      <AboutCars />
      <News />
      <Suspense>
        <PricingCalculator />
      </Suspense>
      <InfoSection :sectionsDetails="SECTIONS_DETAILS" />
      <Contact />
      <MapSection v-if="shouldLoadMaps" />
      <Footer />
    </main>
  </section>
</template>

<script lang="ts">
import {
  computed,
  ComputedRef,
  defineAsyncComponent,
  defineComponent,
} from "vue";
import Hero from "@/components/Hero.vue";
import AboutUs from "@/components/AboutUs.vue";
import ImportSection from "@/components/ImportSection.vue";
import AboutCars from "@/components/AboutCars.vue";
import PricingCalculator from "@/components/PricingCalculator.vue";
import InfoSection from "@/components/InfoSection.vue";
import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";
import SectionDetail from "@/typings/SectionDetail";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

const News = defineAsyncComponent(() => import("@/components/News.vue"));

const MapSection = defineAsyncComponent(
  () => import("@/components/MapSection.vue" /* webpackChunkname: "maps" */)
);

const messages = {
  pl: {
    warehouse: {
      title: "Warsztat",
      subTitle: "Współpracujemy z zaufanym warsztatem i blacharnią",
      description:
        "Zapewniamy kompleksową naprawę Twojego auta - wylicytuj i napraw z nami! Blacharstwo, lakiernictwo, serwis pojazdów i car detailing. Dzięki nam zaoszczędzisz czas na szukaniu miejsca, w którym naprawisz swoje auto. Odbierz gotowy pojazd i ciesz się nim jak najszybciej!",
    },
    financing: {
      title: "Finansowanie",
      subTitle: "Leasing, kredyt, gotówka",
      description:
        "Współpracujemy z doświadczonymi doradcami leasingowymi i kredytowymi, którzy pomogą dobrać finansowanie według Twoich potrzeb przy jak najniższych kosztach.",
    },
  },
  de: {
    warehouse: {
      title: "Werkstatt",
      subTitle:
        "Wir arbeiten mit einer vertrauenswürdigen Werkstatt und Karosseriewerkstatt zusammen",
      description:
        "Wir bieten Ihnen eine umfassende Reparatur Ihres Autos - ersteigern und reparieren Sie bei uns! Karosseriebau, Lackierung, Fahrzeugservice und Fahrzeugaufbereitung. Dank uns sparen Sie Zeit bei der Suche nach einem Ort, an dem Sie Ihr Auto reparieren können. Holen Sie das fertige Fahrzeug ab und genießen Sie es so schnell wie möglich!",
    },
    financing: {
      title: "Finanzierung",
      subTitle: "Leasing, Darlehen, Bargeld",
      description:
        "Wir arbeiten mit erfahrenen Leasing- und Kreditberatern zusammen, die Ihnen bei der Auswahl der Finanzierung nach Ihren Bedürfnissen zu den geringstmöglichen Kosten helfen.",
    },
  },
  en: {
    warehouse: {
      title: "Garage",
      subTitle: "We closely work with a trusted garages and body shops",
      description:
        "We offer full service when it comes to car repair. Mechanical and body work as well as painting, and car detailing. It will save you a great deal of time. You don’t have to search for a trusted garage.  Leave it to us and we will deliver a perfect car as fast as possible.",
    },
    financing: {
      title: "Financing",
      subTitle: "Lease, loan or cash",
      description:
        "Our trusted financial advisors will help you find the best financing for your car at the lowest costs.",
    },
  },
};

export default defineComponent({
  name: "Home",
  components: {
    Hero,
    AboutUs,
    ImportSection,
    AboutCars,
    News,
    PricingCalculator,
    InfoSection,
    Contact,
    MapSection,
    Footer,
  },
  setup() {
    const { t } = useI18n({
      messages,
    });
    const SECTIONS_DETAILS: ComputedRef<SectionDetail[]> = computed(() => [
      {
        title: t("warehouse.title"),
        subTitle: t("warehouse.subTitle"),
        description: t("warehouse.description"),
        backgroundImage: "workshop.webp",
        backgroundImageSmall: "workshop@488w.webp",
      },
      {
        title: t("financing.title"),
        subTitle: t("financing.subTitle"),
        description: t("financing.description"),
        backgroundImage: "finances.webp",
        backgroundImageSmall: "finances@500w.webp",
      },
    ]);

    const store = useStore();
    const shouldLoadMaps = computed(() => store.getters.shouldLoadMaps);

    return { SECTIONS_DETAILS, shouldLoadMaps };
  },
});
</script>

<style lang="scss">
@mixin coverer {
  @apply w-full h-screen absolute top-0 left-0;
}
main {
  margin-top: 100vh;
  @apply w-full relative;
  &::before {
    content: "";
    @include coverer;
    top: -100vh;
  }
}
</style>
