<template>
  <footer class="w-full h-24 bg-white text-black">
    <div
      class="footer__content m-auto h-full flex flex-col ssm:flex-row justify-center items-center"
    >
      <p>Copyright © {{ new Date().getFullYear() }}. DPVCARS</p>
      <div class="divider hidden ssm:block"></div>
      <p>
        Designed by
        <a
          href="https://michalruchel.com/"
          target="_blank"
          class="hover:text-brown"
          rel="noopener"
          >MR</a
        >
      </p>
    </div>
  </footer>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
});
</script>

<style lang="scss">
.footer__content {
  p {
    @apply text-sm md:text-base mx-4;
  }
  .divider {
    height: 1px;
    @apply w-4 bg-black;
  }
}
</style>
