
import SectionDetail from "@/typings/SectionDetail";
import { computed, ComputedRef, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const messages = {
  pl: {
    titleTop: "Z zamiłowania do motoryzacji",
    titleBottom: "Działamy z pasją",
    subTitle: "DPV Cars to połączenie pasji i zamiłowania do motoryzacji",
    sectionsDetails: [
      {
        subTitle:
          "Dzięki naszemu doświadczeniu w branży motoryzacyjnej chcemy dać Ci możliwość spełnienia najskrytszych snów o wymarzonym samochodzie.",
        description:
          "To właśnie pasja sprawia, że doskonale rozumiemy Twoje potrzeby. Nasza praca opiera się na własnych zainteresowaniach, dlatego indywidualne podejście jest dla nas najważniejsze. Twoje zadowolenie jest jednocześnie naszą satysfakcją. Oferujemy Ci kompleksowe i rzetelne wsparcie w wyborze najlepszego samochodu zgodnie z Twoimi preferencjami.",
      },
      {
        subTitle:
          "Firma DPV Cars pośredniczy przy zakupie samochodów z USA i Kanady.",
        description:
          "Dlaczego rynek amerykański, a nie Europejski? Odpowiedź jest bardzo prosta. Stawiamy na najwyższą jakość.  Auta sprowadzane z USA to często samochody ze stosunkowo niskim przebiegiem oraz pełną historią obsługi i napraw, co łatwo sprawdzić za pomocą ogólnodostępnych raportów Carfax i Autocheck . Tak dokładnej transparentności brakuje pojazdom importowanym z państw europejskich, a co za tym idzie,  wbrew poszczególnym opiniom sprowadzanie samochodów ze Stanów Zjednoczonych jest dużo pewniejszym i bezpieczniejszym rozwiązaniem.",
      },
      {
        subTitle: "Importowane auta z USA to nawet do 40% niższa cena.",
        description:
          "Ich standardowe wyposażenia są często o wiele bardziej imponujące niż te na rynku europejskim. Jeśli cenisz sobie funkcjonalność, a estetyka i wygoda jest dla Ciebie równie ważna, ta oferta skierowana jest właśnie do Ciebie. Proponujemy ogólne wsparcie w znalezieniu odpowiedniego samochodu specjalnie dla Ciebie.",
      },
    ],
    carQuote:
      "Jeśli samochód nie sprawia przyjemności, to nie można go okreslić tym mianem.",
  },
  de: {
    titleTop: "Aus Liebe zur Motorisierung",
    titleBottom: "Arbeiten wir mit Leidenschaft",
    subTitle:
      "DPV Cars ist eine Kombination aus Liebe und Begeisterung für Autos",
    sectionsDetails: [
      {
        subTitle:
          "Dank unserer Erfahrung in der Automobilbranche möchten wir Ihnen die Möglichkeit geben, sich Ihre innersten Träume von Ihrem Traumauto zu erfüllen.",
        description:
          "Es ist die Leidenschaft, die uns dazu bringt, Ihre Bedürfnisse perfekt zu verstehen. Unsere Arbeit basiert auf unseren eigenen Passion, daher ist uns eine individuelle Einstellung am wichtigsten. Ihre Zufriedenheit ist auch unsere Zufriedenheit. Wir bieten Ihnen umfassende und zuverlässige Unterstützung bei der Auswahl des besten Autos nach Ihren Wünschen.",
      },
      {
        subTitle:
          "DPV Cars fungiert als Vermittler beim Kauf von Autos aus den USA und Kanada.",
        description:
          "Warum der amerikanische Markt und nicht der europäische? Die Antwort ist ganz einfach. Wir setzen auf höchste Qualität. Aus den USA importierte Autos sind oft Autos mit relativ geringer Laufleistung und einer vollständigen Wartungs- und Reparaturhistorie, die anhand der öffentlich zugänglichen Carfax- und Autocheck-Berichte leicht zu überprüfen ist. Bei aus europäischen Ländern importierten Fahrzeugen fehlt eine solche genaue Transparenz, und demzufolge ist der Import von Autos aus den USA - entgegen der landläufigen Meinung - eine viel sicherere Lösung.",
      },
      {
        subTitle: "Importierte Autos aus den USA sind bis zu 40% günstiger.",
        description:
          "Ihre Serienausstattung ist oft deutlich beeindruckender als die auf dem europäischen Markt. Wenn Sie Wert auf Funktionalität legen und Ihnen Ästhetik und Komfort gleichermaßen wichtig sind, ist dieses Angebot genau das Richtige für Sie. Wir bieten allgemeine Unterstützung bei der Suche nach dem richtigen Auto laut Ihrem Wunsch.",
      },
    ],
    carQuote: "Wenn es keinen Spaß macht, ist es kein Auto.",
  },
  en: {
    titleTop: "We work with passion",
    titleBottom: "For the love of cars",
    subTitle: "DPV Cars is a mixture of love and passion for cars",
    sectionsDetails: [
      {
        subTitle:
          "Our experience in the automotive industry gives you the opportunity to fulfill your innermost dreams about your dream car.",
        description:
          "It’s our passion that enables us to understand your needs better. Our work is fueled by our hobby and that’s why we believe in an individual approach towards our clients. If you are happy – we are happy. We offer full and professional service when it comes to choosing and finding your next car.",
      },
      {
        subTitle: "DPV Cars mediates in buying cars from USA and Canada.",
        description:
          "But why the American market and not the European? The answer is actually quite simple. We focus on the quality - USA and Canada are the places to find it. Cars from that area are usually low mileage and have a full service history. Furthermore, the car's history is easily researched by services like Carfax and Autocheck. A total transparency that is lacking in cars imported from Europe. As a result, importing cars from the USA and Canada is a much safer and more beneficial option.",
      },
      {
        subTitle:
          "Cars imported from the USA and Canada are up to 40% cheaper.",
        description:
          "Cars from North America also come better equipped than the ones from Europe. If you value functionality, aesthetics and you like to feel comfortable, then this offer is a perfect match for you.",
      },
    ],
    carQuote: "If a car doesn't bring you pleasure, you cannot call it a car",
  },
};

export default defineComponent({
  name: "AboutUs",
  setup() {
    const { t } = useI18n({
      messages,
    });

    const sectionsDetails: ComputedRef<SectionDetail[]> = computed(() => [
      {
        subTitle: t("sectionsDetails[0].subTitle"),
        description: t("sectionsDetails[0].description"),
        backgroundImage: "about1.webp",
      },
      {
        subTitle: t("sectionsDetails[1].subTitle"),
        description: t("sectionsDetails[1].description"),
        backgroundImage: "about2.svg",
      },
      {
        subTitle: t("sectionsDetails[2].subTitle"),
        description: t("sectionsDetails[2].description"),
        backgroundImage: "about3.webp",
      },
    ]);

    const store = useStore();

    const aboutUs = ref(null);
    onMounted(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const pageYOffset = (aboutUs.value as any).getBoundingClientRect().top;
      store.commit("setNavTriggerValue", pageYOffset + window.scrollY);
    });

    return { t, sectionsDetails, aboutUs };
  },
});
