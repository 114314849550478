<template>
  <nav
    class="navbar"
    :class="{
      hiddenNavbar: !showNavbar && !isNavOpen,
    }"
  >
    <div class="back__wrapper">
      <LanguagueSwitch :locales="AVAILABLE_LOCALES" theme="dark" />
    </div>
    <div class="logo" v-scroll-to="'.hero'">
      <img src="@/assets/images/logoDark.svg" alt="logo" />
    </div>

    <div class="burger__wrapper">
      <div
        class="nav-mobile"
        id="nav-icon"
        :class="{ open: isNavOpen }"
        @click="setNav(!isNavOpen)"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </nav>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import LanguagueSwitch from "@/components/LanguagueSwitch.vue";
import { AVAILABLE_LOCALES } from "@/main";

export default defineComponent({
  name: "TheNavbar",
  components: { LanguagueSwitch },
  setup() {
    const store = useStore();
    const isNavOpen = computed(() => store.getters.isNavOpen);
    const shouldLoadMaps = (value: boolean) =>
      store.commit("setLoadMaps", value);

    const initialScrollOffset = computed(() => store.getters.navTriggerValue);
    const lastScrollPosition = ref(0);
    const offset = 5;
    const showNavbar = ref(false);
    const desktopMedia = window.matchMedia(
      "(min-width:1024px) and (min-height: 960px)"
    );
    const loadMapsOffset = desktopMedia.matches ? 8000 : 12000;

    const onScroll = () => {
      // lazy load maps component
      if (window.pageYOffset > loadMapsOffset) {
        shouldLoadMaps(true);
      }

      if (window.pageYOffset > initialScrollOffset.value) {
        if (window.pageYOffset < 0) return;
        if (Math.abs(window.pageYOffset - lastScrollPosition.value) < offset)
          return;

        showNavbar.value = window.pageYOffset < lastScrollPosition.value;
        lastScrollPosition.value = window.pageYOffset;
      } else {
        showNavbar.value = false;
      }
    };

    onMounted(() => {
      lastScrollPosition.value = window.pageYOffset;
      window.addEventListener("scroll", onScroll, { passive: true });
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", onScroll);
    });

    return {
      isNavOpen,
      setNav: (nav: boolean) => store.commit("setNav", nav),
      showNavbar,
      AVAILABLE_LOCALES,
    };
  },
  // mounted() {
  //   if (this.$route.meta.initialNav) this.showNavbar = true;
  //   this.lastScrollPosition = window.pageYOffset;
  //   window.addEventListener("scroll", this.onScroll);
  // },
  // beforeUnmount() {
  //   window.removeEventListener("scroll", this.onScroll);
  // },
  // methods: {
  //   onScroll() {
  //     console.log(window.pageYOffset, this.initialScrollOffset);

  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     if (this.lastScrollPosition > this.initialScrollOffset) {
  //       if (window.pageYOffset < 0) return;
  //       if (
  //         Math.abs(window.pageYOffset - this.lastScrollPosition) < this.offset
  //       )
  //         return;

  //       this.showNavbar = window.pageYOffset < this.lastScrollPosition;
  //       this.lastScrollPosition = window.pageYOffset;
  //     }
  //   },
  // },
});
</script>
<style lang="scss">
@import "@/assets/styles/global.scss";
.fadeDown-enter-active,
.fadeDown-leave-active {
  transition: opacity 0.5s;
}
.fadeDown-enter,
.fadeDown-leave-to {
  opacity: 0;
}
.navbar {
  top: 0;
  min-height: 8vh;
  padding: 15px 6vw;
  z-index: 100;
  transition: all 0.8s cubic-bezier(0.77, 0, 0.175, 1);
  @apply fixed w-full bg-brown flex justify-start items-center;
  &.hiddenNavbar {
    transform: translateY(-100%);
  }
  .back__wrapper {
    @apply flex justify-start items-center;
    flex: 1;
  }
  .logo {
    max-width: 40vw;
    max-height: 8vh;
    color: white;
    @apply bg-center bg-cover bg-no-repeat flex items-center justify-center;
    flex-direction: row;
    text-align: center;
    flex: 2;
    &:hover {
      cursor: pointer;
    }
    h3 {
      text-transform: uppercase;
      font-size: 24px;
      letter-spacing: 2px;
      font-weight: bolder;
    }
    img {
      @apply w-full h-full object-contain max-w-full max-h-full;
    }
  }
  .burger__wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    height: 100%;
    p {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  @media (min-width: 360px) {
    .logo {
      max-width: 35vw;
    }
  }

  @media (min-width: 450px) {
    .logo {
      max-width: 30vw;
    }
  }
  @media (min-width: 768px) {
    .logo {
      max-width: 25vw;
    }
  }
  @media (max-width: 850px) and (max-height: 450px) and (orientation: landscape) {
    .logo {
      max-width: 30vw;
      max-height: 10vh;
    }
  }
  @media (min-width: 1024px) {
    .logo {
      max-width: 20vw;
    }
  }
  @media (min-width: 1280px) {
    .logo {
      max-width: 15vw;
    }
  }
  @media (min-width: 1650px) {
    .logo {
      max-width: 10vw;
    }
  }
}
</style>
