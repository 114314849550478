<template>
  <section class="contact" id="contact">
    <div class="contact__content">
      <div class="contact__title">
        <h1 class="h1">{{ t("contact.title") }}</h1>
        <hr />
        <h2 class="h2">
          {{ t("contact.subTitle") }}
        </h2>
      </div>
      <div class="contact__container">
        <div class="contact__form--container">
          <h2 class="h2">{{ t("form.title") }}</h2>
          <FormComponent />
        </div>
        <div class="contact__details--container">
          <div class="contact__details">
            <h2 class="h2">{{ t("contactDetails.title") }}</h2>
            <hr />
            <div class="direct__contact">
              <h3>{{ t("contactDetails.writeOrCall") }}</h3>
              <a :href="`mailto:${contactDetails.email}`" rel="noopener">{{
                contactDetails.email
              }}</a>
              <a
                v-for="phone in contactDetails.phones"
                :key="phone"
                :href="`tel:${phone}`"
                rel="noopener"
                @click="onPhoneNumberClick"
                >{{ phone }}</a
              >
            </div>
            <div class="location">
              <h3>{{ t("contactDetails.headquarters") }}</h3>
              <a
                :href="contactDetails.location.link"
                target="_blank"
                rel="noopener"
                >{{ contactDetails.location.title }}</a
              >
            </div>
            <div class="socials">
              <h3>{{ t("contactDetails.checkSocials") }}</h3>
              <ul class="flex">
                <li>
                  <a
                    href="https://www.facebook.com/DPVcars/"
                    target="_blank"
                    class="socialIcon"
                    aria-label="Instagram"
                    rel="noopener"
                  >
                    <svg
                      class="svgIcon"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z"
                      />
                    </svg>
                  </a>
                </li>
                <li class="ml-4">
                  <a
                    href="https://www.instagram.com/dpvcarspl"
                    target="_blank"
                    class="socialIcon"
                    aria-label="Instagram"
                    rel="noopener"
                  >
                    <svg
                      class="svgIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="-6 -6 36 36"
                    >
                      <path
                        d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
                      />
                    </svg>
                  </a>
                </li>
                <li class="ml-4">
                  <a
                    href="https://www.youtube.com/channel/UCiYdsGnBqPKE0NzUexm01fg"
                    target="_blank"
                    class="socialIcon"
                    aria-label="Youtube"
                    rel="noopener"
                  >
                    <svg
                      class="svgIcon items-center m-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="-6 -6 36 36"
                    >
                      <path
                        d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
            <span class="logo">#dpvcars</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { computed, ComputedRef, defineComponent } from "vue";
import FormComponent from "@/components/FormComponent.vue";
import { useI18n } from "vue-i18n";
import { useGtm } from "@gtm-support/vue-gtm";

interface ContactDetails {
  email: string;
  phones: string[];
  location: {
    title: string;
    link: string;
  };
}

const messages = {
  pl: {
    contact: {
      title: "Porozmawiajmy o samochodzie",
      subTitle: "Jeżeli masz jakiekolwiek pytania zapraszamy do kontaktu",
    },
    form: {
      title: "Napisz do nas",
    },
    contactDetails: {
      title: "Dane kontaktowe",
      writeOrCall: "Napisz lub zadzwoń",
      headquarters: "Nasza siedziba",
      checkSocials: "Zobacz nas na",
    },
    phones: ["+48 666 57 57 50", "+48 508 32 61 96"],
  },
  de: {
    contact: {
      title: "Besprechen wir über das Auto",
      subTitle: "Wenn Sie Fragen haven, kontaktieren Sie uns bitte",
    },
    form: {
      title: "Schreiben Sie zu uns",
    },
    contactDetails: {
      title: "Kontaktdaten",
      writeOrCall: "Schreiben oder anrufen",
      headquarters: "Unser Hauptsitz",
      checkSocials: "Sehen Sie uns auf",
    },
    phones: ["+49 151 41950212", "+48 666 57 57 50"],
  },
  en: {
    contact: {
      title: "Let's talk about your car",
      subTitle:
        "If you have any questions, feel free to call us or send an email",
    },
    form: {
      title: "Write to us",
    },
    contactDetails: {
      title: "Contact details",
      writeOrCall: "Message or call us",
      headquarters: "Our headquarters",
      checkSocials: "Follow us on",
    },
    phones: ["+49 151 41950212", "+48 666 57 57 50"],
  },
};

export default defineComponent({
  name: "Contact",
  components: { FormComponent },
  setup() {
    const { t } = useI18n({
      messages,
    });

    const gtm = useGtm();

    const onPhoneNumberClick = () => {
      gtm?.trackEvent({
        event: "interaction",
        category: "phone",
        action: "click",
        label: "phone number click",
        value: 5000,
        noninteraction: false,
      });
    };

    const contactDetails: ComputedRef<ContactDetails> = computed(() => ({
      email: "info@dpvcars.pl",
      phones: [t("phones[0]"), t("phones[1]")],
      location: {
        title: "Mieszczańska 11, 50-201 Wrocław",
        link: "https://goo.gl/maps/PBkFBgjPMo13Z7yL8",
      },
    }));

    return { t, contactDetails, onPhoneNumberClick };
  },
});
</script>
<style lang="scss">
.contact {
  @apply bg-black;
  .contact__content {
    @apply flex items-center justify-center flex-col py-2xl px-lg max-w-6xl m-auto;
    .contact__title {
      @apply flex justify-center items-center flex-col text-center;
      h1 {
        @apply text-brown text-xl ssm:text-4xl;
      }
      hr {
        @apply bg-white mt-8;
      }
      h2 {
        @apply uppercase mt-8 text-base ssm:text-lg md:text-xl font-normal;
      }
    }
    .contact__container {
      @apply w-full max-w-4xl pt-2xl lg:pt-4xl flex flex-col-reverse justify-center items-center lg:items-start lg:justify-between lg:flex-row;
      h2 {
        @apply text-white font-bold;
      }
      .contact__form--container {
        @apply w-full h-full max-w-sm;
        h2 {
          @apply text-center lg:text-left mt-8 lg:mt-0;
        }
        form {
          @apply mt-8;
        }
      }
      .contact__details--container {
        @apply w-full h-full lg:w-auto max-w-xs;
        .contact__details {
          @apply flex justify-center items-center lg:items-start flex-col text-center lg:text-left;
          hr {
            @apply bg-white mt-8;
          }
          h3 {
            @apply text-brown uppercase;
          }
          .direct__contact,
          .location {
            @apply flex flex-col pt-md;
            p,
            a,
            ul {
              @apply mt-1;
            }
            a {
              @apply hover:text-brown py-md;
              @screen md {
                padding: 0;
              }
            }
          }
          .location,
          .socials {
            @apply pt-lg;
          }
          .socials {
            ul {
              @apply pt-lg;
            }
          }
          .logo {
            @apply pt-lg text-secondaryBlack text-5xl md:text-6xl font-extrabold italic uppercase;
          }
        }
      }
    }
  }
}
.socialIcon {
  transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1),
    background 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  @apply flex justify-center items-center text-gray hover:text-gray bg-white hover:bg-brown rounded-full shadow transition duration-150 ease-in-out;
}
.svgIcon {
  @apply w-10 h-10 sm:w-8 sm:h-8 fill-current text-black;
}
</style>
